import theme from '@ic-theme'
import { PayloadAction } from '@reduxjs/toolkit'
import net from '@spa-core-js/services/networkSvc'
import { AddressLookupFields } from '@spa-ec/components/Checkout/constants'
import {
    ADYEN_PAYMENT_PROVIDER_CODE,
    ADYEN_SESSION_CONFIG_PAYMENT_PROVIDER,
} from '@spa-ec/components/Checkout/PaymentProviders/AdyenPayment'
import { buffers } from 'redux-saga'
import { actionChannel, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { TrackingActionTypes } from '../../tracking/constants'
import { updateSessionConfig } from '../app/actions'
import { SessionConfig } from '../app/interfaces'
import { fetchCart } from '../cart/actions'
import { NAME as cartReducerName } from '../cart/constants'
import { parseCartEntries } from '../cart/utils'
import { navigateTo, redirectTo } from '../navigation/actions'
import { Price } from '../products/interfaces'
import { selectSessionConfig } from '../utils'
import { saveCheckoutFormData as saveCheckoutFormDataAction, setDisabledFields, updateCheckoutSettings } from './actions'
import { AccountType, ActionTypes, CheckoutFormKey, CheckoutSettingsKey, NAME as checkoutReducerName } from './constants'
import {
    Address,
    AddressLookupResult,
    AdyenPaymentSession,
    CheckDeliveryModePayload,
    CheckoutFormData,
    CheckoutStore,
    CheckPaymentModePayload,
    CheckPaymentModeResult,
    CheckSeparateShipmentAddressResult,
    DeliveryMode,
    DoAddressLookupPayload,
    FetchedCheckoutSettingsResult,
    FetchedPickupPoints,
    FetchOrderConfirmationPayload,
    FetchPickupPointsPayload,
    InstaboxPickupPointsResult,
    NotYouResult,
    OrderConfirmation,
    PaymentMode,
    PickupPoint,
    PickupPointResult,
    PlaceOrderError,
    PlaceOrderPayload,
    PlaceOrderResult,
    SaveCheckoutFormDataBatchPayload,
    SaveCheckoutFormDataPayload,
    Settings,
    ValidateAndSaveCheckoutFormDataBatchPayload,
    ValidateAndSaveCheckoutFormDataPayload,
    ValidateFieldsResult,
} from './interfaces'
import { Logger } from '@spa-core/logger'

export function* fetchOrderConfirmation({ payload }: any) {
    const { orderId }: FetchOrderConfirmationPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/checkout/order/${orderId}`
    try {
        const orderConfirmation: OrderConfirmation = yield call(() => net.get(url))
        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.config.placeholderImageSrc}`
        if (orderConfirmation.code === null) {
            yield put(navigateTo({ url: sessionConfig.urlPrefix }))
        } else {
            yield put({
                type: ActionTypes.FETCHED_ORDER_CONFIRMATION,
                payload: {
                    orderConfirmation: {
                        ...orderConfirmation,
                        entries: parseCartEntries(orderConfirmation.entries, fallbackImage),
                    },
                    requestOrderId: orderId,
                },
            })
            yield put({
                type: TrackingActionTypes.FETCHED_ORDER_CONFIRMATION_DATA,
                payload: {
                    data: orderConfirmation,
                    upsellCompleted: false,
                },
            })
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchDeliveryModes() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/${sessionConfig.siteName.toLowerCase()}/${sessionConfig.currentBaseStoreId}/checkout/deliveryModes?fetch=true`
    try {
        const deliveryModes: DeliveryMode[] = yield call(() => net.get(url))
        yield put({
            type: ActionTypes.FETCHED_DELIVERY_MODES,
            payload: {
                deliveryModes,
            },
        })
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchCheckoutSettings() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const paymentModesUrl: string = `${sessionConfig.urlPrefix}/rest/v1/${sessionConfig.siteName.toLowerCase()}/${sessionConfig.currentBaseStoreId}/checkout/paymentModes?fetch=true`
    try {
        const settings: Settings = yield call(() => net.get(`${sessionConfig.urlPrefix}/rest/v1/checkout?fetch=true`))
        let addresses: Address[] = []
        if (settings.userLoggedIn) {
            addresses = yield call(() => net.get(`${sessionConfig.urlPrefix}/rest/v1/users/current/account/addresses`))
        }
        const paymentModes: PaymentMode[] = yield call(() => net.get(paymentModesUrl))
        const payload: FetchedCheckoutSettingsResult = {
            settings,
            addresses,
            paymentModes,
        }
        yield put({
            type: ActionTypes.FETCHED_CHECKOUT_SETTINGS,
            payload,
        })
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, paymentModesUrl)
    }
}

export function* fetchPickupPoints({ payload }: any) {
    const { postalCode, address, email, deliveryProductId }: FetchPickupPointsPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)

    const sanitizedPostalCode: string = postalCode?.replace(/ /g, '')
    const shipmentServiceBaseUrl: string = sessionConfig.shipmentLookupURL.match(/.*shipment-lookup-svc/)?.[0]
    const encodedAddress: string = encodeURIComponent(address || '')
    const encodedEmail: string = encodeURIComponent(email || '')
    const instaboxUrl: string = `${shipmentServiceBaseUrl}/instaboxServiceLookup/${sanitizedPostalCode}/${encodedAddress}/${encodedEmail}`

    const userType: string = sessionConfig.b2bMode ? 'b2b' : 'b2c'
    const url: string = `${sessionConfig.shipmentLookupURL}/${sessionConfig.countryIso}/${sanitizedPostalCode}/${deliveryProductId}/${userType}`
    try {
        yield put({
            type: ActionTypes.SET_FETCHING_PICKUP_POINTS,
            payload: {
                fetchingPickupPoints: true,
            },
        })
        let pickupPointsResult: PickupPointResult[] = []
        /**
         * TODO: Fix hacky instabox implementation
         */
        const isInstaboxShipmentMethod: boolean = [8900, 8950].includes(parseInt(deliveryProductId))
        if (isInstaboxShipmentMethod) {
            const instaboxResult: InstaboxPickupPointsResult = yield call(() =>
                net.getWithAuthToken(instaboxUrl, sessionConfig.authToken),
            )
            pickupPointsResult = instaboxResult?.DropPoints
        } else {
            pickupPointsResult = yield call(() => net.getWithAuthToken(url, sessionConfig.authToken))
        }

        if (pickupPointsResult?.length) {
            const pickupPoints: PickupPoint[] = []
            pickupPoints.push(
                ...pickupPointsResult.map((pickupPoint: PickupPointResult) => ({
                    value: pickupPoint.OriginalId,
                    text: `${pickupPoint.Name}, ${pickupPoint.Address}`,
                })),
            )
            const fetchedPickupPointsPayload: FetchedPickupPoints = {
                postalCode,
                deliveryProductId,
                pickupPoints,
            }
            yield put({
                type: ActionTypes.FETCHED_PICKUP_POINTS,
                payload: fetchedPickupPointsPayload,
            })
        } else {
            const fetchedPickupPointsPayload: FetchedPickupPoints = {
                postalCode,
                deliveryProductId,
            }
            yield put({
                type: ActionTypes.FETCHED_PICKUP_POINTS,
                payload: fetchedPickupPointsPayload,
            })
        }
        yield put({
            type: ActionTypes.SET_FETCHING_PICKUP_POINTS,
            payload: {
                fetchingPickupPoints: false,
            },
        })
    } catch (e: any) {
        /**
         * Error
         */
        yield put({
            type: ActionTypes.SET_FETCHING_PICKUP_POINTS,
            payload: {
                fetchingPickupPoints: false,
            },
        })
        Logger.error({ message: e.message }, e.code, e.status, instaboxUrl, url)
    }
}

export function* checkDeliveryMode({ payload }: any) {
    const { postalCode, deliveryModeCode, pickupPointId, pickupPointName, paymentModeCode }: CheckDeliveryModePayload = payload
    const data: string = `deliveryModeCode=${deliveryModeCode}&paymentModeCode=${paymentModeCode || ''}&postCode=${postalCode}`
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/checkout/single/checkDeliveryMode`
    try {
        const result: CheckPaymentModeResult = yield call(() => net.post(url, data))
        if (result) {
            if (!result.valid) {
                yield put({
                    type: ActionTypes.SET_DELIVERY_MODE_ERROR_MESSAGE,
                    payload: {
                        deliveryModeErrorMessage: result.message,
                    },
                })
            } else {
                yield put({
                    type: ActionTypes.SET_DELIVERY_MODE_ERROR_MESSAGE,
                    payload: {
                        deliveryModeErrorMessage: undefined,
                    },
                })
                const deliveryModes: DeliveryMode[] = yield select(
                    (state) => state?.reducers?.[checkoutReducerName]?.deliveryModes,
                )
                const selectedDeliveryMode: DeliveryMode = deliveryModes.find(({ code }) => code === deliveryModeCode)
                if (!selectedDeliveryMode.deliveryProductId) {
                    yield put(
                        saveCheckoutFormDataAction({
                            key: CheckoutFormKey.PICKUP_POINT_ID,
                            value: null,
                        }),
                    )
                } else if (selectedDeliveryMode.deliveryProductId && pickupPointId) {
                    yield put(
                        saveCheckoutFormDataAction({
                            key: CheckoutFormKey.PICKUP_POINT_ID,
                            value: pickupPointId,
                        }),
                    )
                    yield put(
                        saveCheckoutFormDataAction({
                            key: CheckoutFormKey.PICKUP_POINT_NAME,
                            value: pickupPointName,
                        }),
                    )
                }
            }
            yield put(fetchCart())
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url, data)
    }
}

export function* notYou() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/checkout/notYou`
    try {
        yield call(() => net.get(url) as NotYouResult)
        yield put({
            type: ActionTypes.RESET_CHECKOUT_FORM_DATA,
        })
        yield put(
            updateCheckoutSettings({
                key: CheckoutSettingsKey.SHOW_PREFILLED_USER_BLOCK,
                value: false,
            }),
        )
        yield put(
            updateCheckoutSettings({
                key: CheckoutSettingsKey.IDENTIFIED_CUSTOMER,
                value: false,
            }),
        )
        yield put({
            type: ActionTypes.SET_DID_ADDRESS_LOOKUP,
            payload: {
                didAddressLookup: false,
            },
        })
        const totalCartPrice: Price = yield select((state) => state?.reducers?.[cartReducerName]?.totalCartPrice)
        if (totalCartPrice?.value === 0) {
            yield put({
                type: ActionTypes.SET_DO_ADDRESS_LOOKUP_LOADING,
                loading: false,
            })
            yield put(
                setDisabledFields({
                    fields: AddressLookupFields.filter((fieldKey: CheckoutFormKey) => fieldKey !== CheckoutFormKey.FIRST_NAME),
                    disabled: true,
                }),
            )
        } else {
            yield put(
                setDisabledFields({
                    fields: AddressLookupFields,
                    disabled: false,
                }),
            )
        }
        yield put(updateSessionConfig())
        yield put(fetchCart())
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* checkSeparateShipmentAddress({ payload }: any) {
    const { paymentMethod } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/checkout/single/checkSeparateShipmentAddress`
    const data: string = `paymentModeCode=${paymentMethod || ''}`
    try {
        const result: CheckSeparateShipmentAddressResult = yield call(() => net.post(url, data))
        if (!result.valid) {
            yield put(
                saveCheckoutFormDataAction({
                    key: CheckoutFormKey.DIFFERENT_DELIVERY_ADDRESS,
                    value: false,
                    valid: true,
                    errorMessage: result.notValidMessage,
                }),
            )
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url, data)
    }
}

export function* checkPaymentMode({ payload }: any) {
    const {
        personalIdentityNumber,
        birthDate,
        organizationNumber,
        deliveryModeCode,
        paymentModeCode,
        differentDeliveryAddress,
    }: CheckPaymentModePayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const hasPersonnummer: boolean =
        personalIdentityNumber !== undefined ||
        birthDate !== undefined ||
        (sessionConfig.b2bMode && organizationNumber !== undefined)

    const data: string = `deliveryModeCode=${deliveryModeCode}&paymentModeCode=${paymentModeCode}&personnummer=${hasPersonnummer}&separateShippingAddress=${differentDeliveryAddress}`
    const url: string = `${sessionConfig.urlPrefix}/checkout/single/checkPaymentMode`
    try {
        const result = yield call(() => net.post(url, data))
        if (result) {
            yield put(fetchCart())
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url, data)
    }
}

export function* validateAndSaveCheckoutFormDataBatch({ payload }: any) {
    try {
        const { batch }: ValidateAndSaveCheckoutFormDataBatchPayload = payload
        for (let i: number = 0; i < batch.length; ++i) {
            yield call(() =>
                validateAndSaveCheckoutFormData({
                    payload: batch[i],
                }),
            )
        }
    } catch {
        /**
         * Error
         */
    }
}

export function* validateAndSaveCheckoutFormData({ payload }: any) {
    const { key, value }: ValidateAndSaveCheckoutFormDataPayload = payload
    const url: string = `/checkout/single/validateField.json?idKey=${encodeURIComponent(key)}&value=${encodeURIComponent(value)}`
    try {
        const validationResult: ValidateFieldsResult = yield call(() => net.get(url))
        yield call(() =>
            saveCheckoutFormData({
                payload: {
                    key,
                    errorMessage: validationResult.errorMessage,
                    value: validationResult.value || '',
                    valid: validationResult.valid,
                },
            }),
        )
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

function* saveCheckoutFormData({ payload }: any) {
    const { key, value, errorMessage, valid = true }: SaveCheckoutFormDataPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/checkout/saveCheckoutFormData`
    const data: string = `field=${key}&value=${value}`
    try {
        yield put({
            type: ActionTypes.UPDATE_CHECKOUT_FORM_FIELD,
            payload: {
                key,
                errorMessage,
                value,
                valid,
            },
        })
        yield call(() => net.post(url, data))
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url, data)
    }
}

function* saveCheckoutFormDataBatch({ payload }: any) {
    try {
        const { batch }: SaveCheckoutFormDataBatchPayload = payload
        for (let i: number = 0; i < batch.length; ++i) {
            yield call(() =>
                saveCheckoutFormData({
                    payload: batch[i],
                }),
            )
        }
    } catch (error) {
        /**
         * Error
         */
    }
}

export function* placeOrder({ payload }: any) {
    const { checkoutForm }: PlaceOrderPayload = payload
    const checkoutStore: CheckoutStore = yield select((state) => state?.reducers?.[checkoutReducerName])
    if (checkoutStore.placingOrder) return

    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const isAdyenPayment: boolean =
        checkoutForm[CheckoutFormKey.PAYMENT_METHOD] === ADYEN_PAYMENT_PROVIDER_CODE &&
        sessionConfig.paymentProvider === ADYEN_SESSION_CONFIG_PAYMENT_PROVIDER

    /**
     * Manually clear CheckoutFormKey.CHECKOUT_FORM
     * CheckoutFormKey.CHECKOUT_FORM only appears to communicate
     * errors not directly related to input fields and will therefore
     * need to be cleared explicitly
     */
    yield put({
        type: ActionTypes.CLEAR_CHECKOUT_FORM_ERROR_MESSAGE,
    })

    yield put({
        type: ActionTypes.SET_PLACING_ORDER,
        payload: {
            placingOrder: true,
        },
    })
    if (sessionConfig.b2bMode) {
        checkoutForm.companyType = 'StockCorporation'
    }
    if (sessionConfig.b2bMode && checkoutStore?.settings?.separateInvoiceEmail && checkoutForm.invoiceEmail != null) {
        checkoutForm.email = checkoutForm.invoiceEmail
    }
    yield put({
        type: TrackingActionTypes.SEND_PLACE_ORDER,
        payload: {
            paymentOption: checkoutForm.paymentMethod,
            shipmentMethod: checkoutForm.shipmentMethod,
        },
    })
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/checkout/placeOrder`
    try {
        const result: PlaceOrderResult = yield call(() => net.postJSON(url, JSON.stringify(checkoutForm)))
        if (result) {
            if (result?.errors?.length) {
                /**
                 * Back end form validation failed, set form errors with localized error messages
                 * to checkoutFormData in store
                 */
                const checkoutFormData: CheckoutFormData = checkoutStore.checkoutFormData
                result?.errors?.forEach((error: PlaceOrderError) => {
                    checkoutStore.checkoutFormData[error.objectName] = {
                        ...checkoutFormData[error.objectName],
                        errorMessage: error.message,
                        value: checkoutForm[error.objectName],
                        valid: false,
                    }
                })
                yield put({
                    type: ActionTypes.UPDATE_CHECKOUT_FORM,
                    payload: {
                        checkoutFormData,
                    },
                })
            } else if (result.redirectUrl && result.success && !isAdyenPayment) {
                yield put(fetchCart())
                if (result.redirectUrl.match('payments')) {
                    yield put(
                        navigateTo({
                            url: result.redirectUrl,
                        }),
                    )
                } else {
                    yield put(
                        redirectTo({
                            url: result.redirectUrl,
                        }),
                    )
                }
            } else if (isAdyenPayment && result.placedOrderCode) {
                yield put({
                    type: ActionTypes.SET_PLACED_ORDER_ID,
                    payload: {
                        placedOrderCode: result.placedOrderCode,
                        placedOrderRedirectUrl: result.redirectUrl,
                    },
                })
                yield put(
                    navigateTo({
                        url: `${window.location.pathname}#completePayment_${result.placedOrderCode}`,
                    }),
                )
            }
        }
        if (!isAdyenPayment) {
            yield put(fetchCart())
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url, JSON.stringify(checkoutForm))
    }
    yield put({
        type: ActionTypes.SET_PLACING_ORDER,
        payload: {
            placingOrder: false,
        },
    })
}

export function* doAddressLookup({ payload }: any) {
    const { personalIdentityNumber, organizationNumber }: DoAddressLookupPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    let url: string = sessionConfig.addressLookupURL
    let ssnNo: string = personalIdentityNumber
    const accountType: AccountType = sessionConfig.b2bMode ? AccountType.CORPORATE : AccountType.CONSUMER
    if (accountType === AccountType.CONSUMER) {
        if (!sessionConfig.unmaskedPersonalNumber) {
            ssnNo = personalIdentityNumber
        } else {
            ssnNo = sessionConfig.unmaskedPersonalNumber
        }
        url += '/private/'
    } else if (accountType === AccountType.CORPORATE) {
        ssnNo = organizationNumber
        url += '/company/'
    }

    url += ssnNo

    yield put({
        type: ActionTypes.SET_DO_ADDRESS_LOOKUP_LOADING,
        payload: {
            loading: true,
        },
    })

    yield put({
        type: ActionTypes.SET_DO_ADDRESS_LOOKUP_ERROR,
        payload: {
            errored: false,
        },
    })

    try {
        const clearAddressLookUpURL: string = `${sessionConfig.urlPrefix}/checkout/single/clearAddressLookedUp`
        yield call(() => net.post(clearAddressLookUpURL))

        const lookedUpAddress: AddressLookupResult = yield call(() => net.getWithAuthToken(url, sessionConfig.authToken))
        if (lookedUpAddress && lookedUpAddress.Address) {
            // handling b2c only for now
            const saveAddressLookUpURL: string = `${sessionConfig.urlPrefix}/checkout/single/setAddressLookedUp`
            yield call(() => net.postJSON(saveAddressLookUpURL, JSON.stringify(lookedUpAddress)))

            const address: string = lookedUpAddress.Address
            let line1: string = lookedUpAddress.Address
            let line2: string = ''
            if (address.length > 35) {
                line1 = address.substring(0, 35)
                line2 = address.substring(35)
            }
            if (line2.length > 35) {
                line2 = line2.substring(0, 35)
            }
            if (sessionConfig.b2bMode) {
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.COMPANY_NAME,
                        value: lookedUpAddress.Name,
                        valid: true,
                    }),
                )
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.LINE1,
                        value: line1,
                        valid: true,
                    }),
                )
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.LINE2,
                        value: line2,
                        valid: true,
                    }),
                )
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.TOWN_CITY,
                        value: lookedUpAddress.City,
                        valid: true,
                    }),
                )
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.ADDRESS_ID,
                        value: undefined,
                        valid: true,
                    }),
                )
                yield put({
                    type: ActionTypes.SET_SELECTED_DELIVERY_ADDRESS,
                    payload: {
                        address: undefined,
                    },
                })
                const postcode: string = lookedUpAddress.ZipCode
                yield validateAndSaveCheckoutFormData({
                    payload: {
                        key: CheckoutFormKey.POSTCODE,
                        value: postcode,
                    },
                })
            } else {
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.FIRST_NAME,
                        value: lookedUpAddress.FirstName,
                        valid: true,
                    }),
                )
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.LAST_NAME,
                        value: lookedUpAddress.LastName,
                        valid: true,
                    }),
                )
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.LINE1,
                        value: line1,
                        valid: true,
                    }),
                )
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.LINE2,
                        value: line2,
                        valid: true,
                    }),
                )
                yield put(
                    saveCheckoutFormDataAction({
                        key: CheckoutFormKey.TOWN_CITY,
                        value: lookedUpAddress.City,
                        valid: true,
                    }),
                )
                const postcode: string = lookedUpAddress.ZipCode
                yield validateAndSaveCheckoutFormData({
                    payload: {
                        key: CheckoutFormKey.POSTCODE,
                        value: postcode,
                    },
                })
            }
            yield put(
                saveCheckoutFormDataAction({
                    key: CheckoutFormKey.PERSONAL_NUMBER_LOOKED_UP,
                    value: true,
                    valid: true,
                }),
            )
            yield put({
                type: ActionTypes.SET_DID_ADDRESS_LOOKUP,
                payload: {
                    didAddressLookup: true,
                },
            })
            const totalCartPrice: Price = yield select((state) => state?.reducers?.[cartReducerName]?.totalCartPrice)
            // select invoice payment method if cart total is 0, for some reason
            if (totalCartPrice?.value === 0) {
                yield put({
                    type: ActionTypes.SET_SELECTED_PAYMENT_MODE,
                    payload: {
                        paymentMode: undefined,
                    },
                })
            }
        } else {
            yield put({
                type: ActionTypes.SET_DO_ADDRESS_LOOKUP_ERROR,
                payload: {
                    errored: true,
                },
            })
        }
    } catch (e: any) {
        yield put({
            type: ActionTypes.SET_DO_ADDRESS_LOOKUP_ERROR,
            payload: {
                errored: true,
            },
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
    yield put({
        type: ActionTypes.SET_DO_ADDRESS_LOOKUP_LOADING,
        payload: {
            loading: false,
        },
    })
}

export function* getAdyenPaymentSession({ payload }: PayloadAction<string>) {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    if (payload) {
        const url: string = `${sessionConfig.urlPrefix}/rest/v1/adyen/checkout/paymentSession/${payload}`
        try {
            const adyenPaymentSession: AdyenPaymentSession = yield call(() => net.get(url))
            if (adyenPaymentSession) {
                yield put({
                    type: ActionTypes.FETCHED_ADYEN_PAYMENT_SESSION,
                    payload: adyenPaymentSession,
                })
            }
        } catch (e: any) {
            yield put({
                type: ActionTypes.TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED,
                payload: true,
            })
            Logger.error({ message: e.message }, e.code, e.status, url)
        }
    }
}

/**
 * Added a request channel to make sure only one requests is used for creating the session at the backend
 */
export function* watchAdyenPaymentSessionInitRequests() {
    const requestChannel = yield actionChannel(ActionTypes.FETCH_ADYEN_PAYMENT_SESSION, buffers.none())

    while (true) {
        const action = yield take(requestChannel)
        yield call(getAdyenPaymentSession, action)
    }
}

export function* finalizeAdyenPaymentSession({ payload }: PayloadAction<string>) {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    if (payload) {
        const url: string = `${sessionConfig.urlPrefix}/rest/v1/adyen/checkout/payment/response/${payload}`
        try {
            yield call(() => net.post(url))
        } catch (e: any) {
            Logger.error({ message: e.message }, e.code, e.status, url)
        }
    }
}

export const watchers = [
    takeEvery(ActionTypes.VALIDATE_AND_SAVE_CHECKOUT_FORM_DATA_BATCH, validateAndSaveCheckoutFormDataBatch),
    takeEvery(ActionTypes.SAVE_CHECKOUT_FORM_DATA, saveCheckoutFormData),
    takeEvery(ActionTypes.SAVE_CHECKOUT_FORM_DATA_BATCH, saveCheckoutFormDataBatch),
    takeEvery(ActionTypes.VALIDATE_AND_SAVE_CHECKOUT_FORM_DATA, validateAndSaveCheckoutFormData),
    takeLatest(ActionTypes.FETCH_ORDER_CONFIRMATION, fetchOrderConfirmation),
    takeLatest(ActionTypes.NOT_YOU, notYou),
    takeLatest(ActionTypes.PLACE_ORDER, placeOrder),
    takeLatest(ActionTypes.FETCH_CHECKOUT_SETTINGS, fetchCheckoutSettings),
    takeLatest(ActionTypes.DO_ADDRESS_LOOKUP, doAddressLookup),
    takeLatest(ActionTypes.CHECK_PAYMENT_MODE, checkPaymentMode),
    takeLatest(ActionTypes.CHECK_DELIVERY_MODE, checkDeliveryMode),
    takeLatest(ActionTypes.FETCH_PICKUP_POINTS, fetchPickupPoints),
    takeLatest(ActionTypes.FINALIZE_ADYEN_PAYMENT_SESSION, finalizeAdyenPaymentSession),
    takeLatest(ActionTypes.CHECK_SEPARATE_SHIPMENT_ADDRESS, checkSeparateShipmentAddress),
    takeLatest(ActionTypes.FETCH_DELIVERY_MODES, fetchDeliveryModes),
    watchAdyenPaymentSessionInitRequests(),
]
