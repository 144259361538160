import { Logger } from '@spa-core/logger'
import { isPuppeteer } from '@spa-ec/components/Loader/utils'
import deriveArea from '../../util/deriveArea'
import browserSvc from '@spa-core-js/services/browserSvc'
import { GoogleAnalyticsCategory, MatomoLevel, TrackingActionTypes } from '../constants'
import { NAME as appReducerName } from '../../store/app/constants'
import { MatomoUtil } from '../utils/MatomoUtil'

/**
 * Start listening for Cookiebot consent to enable Matomo
 */
new MatomoUtil().initMatomo()

let spaSessionSent: boolean = false
let firstPageView: boolean = true

function getMeta(metaName) {
    const metas = document.getElementsByTagName('meta')

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content')
        }
    }

    return ''
}
let hasessionid

const fixValue = (value) => {
    if (typeof value === 'string') {
        return parseFloat(value.replace(/,/g, '.'))
    }
    return value
}

const analytics = (store) => (next) => (action) => {
    if (!store) {
        return
    }
    let MATOMO_LEVEL
    if (typeof window !== 'undefined') {
        MATOMO_LEVEL = window['MATOMO_LEVEL'] || MatomoLevel.ALL
    }
    const sessionConfig = store?.reducers?.[appReducerName]?.sessionConfig || window['sessionConf']
    if (!sessionConfig.isSPA || !window['_paq'] || MATOMO_LEVEL === 0) {
        next(action)
        return
    }

    let pCount
    let cCount
    let mCount

    if (!spaSessionSent && !isPuppeteer && typeof window !== 'undefined' && window['_paq']) {
        spaSessionSent = true
        hasessionid = getMeta('HASESSIONID')
        window['_paq'].push(['setCustomVariable', 1, 'HASESSIONID', hasessionid, 'visit'])
        window['_paq'].push(['trackEvent', 'SESSION', 'SPA_SESSION', 'VAL'])
        window['_paq'].push(['setUserId', browserSvc.cookieGet('ID2')])
    }

    const { type, payload } = action
    if (payload?.matomoLevel <= MATOMO_LEVEL) {
        switch (type) {
            case TrackingActionTypes.PAGE_VIEW:
                if (!firstPageView) {
                    window['_paq'].push(['setCustomUrl', decodeURIComponent(location.href)])
                    window['_paq'].push(['trackPageView'])
                }
                firstPageView = false
                break
            case TrackingActionTypes.FETCHED_CART:
                window['_paq'].push(['clearEcommerceCart'])
                payload?.cartData?.entries?.forEach((entry) => {
                    const cat: string[] = []
                    if (entry?.product?.categories?.length > 0) {
                        for (let j = 0; j < entry.product.categories.length; j++) {
                            cat.push(entry.product.categories[j].code)
                        }
                    }
                    window['_paq'].push([
                        'addEcommerceItem',
                        entry.product.code, // (Required) productSKU
                        entry.product.name, // (Optional) productName
                        cat, // (Optional) productCategory
                        entry.buyPrice.value, // (Recommended) price
                        entry.quantity, // (Optional, defaults to 1) quantity
                    ])
                })

                // Pass the Cart's Total Value as a numeric parameter
                window['_paq'].push(['trackEcommerceCartUpdate', payload?.cartData?.subTotal?.value])
                break
            case TrackingActionTypes.VALIDATED_CHECKOUT_INPUT_FIELD:
                if (payload.valid === false) {
                    window['_paq'].push(['trackEvent', deriveArea(), 'ADDRESS_INPUT_ERROR', payload.key])
                }
                break
            case TrackingActionTypes.SEND_PLACE_ORDER:
                window['_paq'].push(['trackEvent', deriveArea(), 'PREFERRED_PAYMENT_OPTION', payload.paymentOption])
                window['_paq'].push(['trackEvent', deriveArea(), 'PREFERRED_SHIPMENT_METHOD', payload.shipmentMethod])
                break
            case TrackingActionTypes.FETCHED_ORDER_CONFIRMATION_DATA:
                try {
                    if (!payload?.data?.affiliateReportData) return
                    // No point of delaying these as they are sent at once anyway
                    const entriesLength: number = payload?.data?.entries?.length || 0
                    for (let i = 0; i < entriesLength; i++) {
                        const entry: any = payload.data.entries[i]
                        const categories: string[] = []
                        for (let j = 0; j < entry.product.categories.length; j++) {
                            categories.push(entry.product.categories[j].code)
                        }
                        window['_paq'].push([
                            'addEcommerceItem',
                            entry.product.code, // (Required) productSKU
                            entry.product.name, // (Optional) productName
                            categories,
                            fixValue(entry.buyPriceExVatSEK), // old: buyPrice.value, // (Recommended) price
                            entry.quantity, // (Optional, defaults to 1) quantity
                        ])
                    }

                    // Order Array - Parameters should be generated dynamically
                    window['_paq'].push([
                        'trackEcommerceOrder',
                        payload.data.code, // (Required) orderId
                        fixValue(payload.data.subTotalExVatSEK), // Subtotal excluding VAT and excluding shipping, always in SEK, see IC-14765.
                        fixValue(payload.data.totalTaxSEK), // Tax in SEK, IC-14765.
                        fixValue(payload.data.deliveryCostExVatSEK), // Shipping, excluding tax and in SEK, IC-14765.
                    ])
                } catch (err) {
                    Logger.error('Analytics reporting error:', err)
                }
                break
            case TrackingActionTypes.SESSION_CONFIG_UPDATED:
                window['_paq'].push([
                    'trackEvent',
                    payload.gaCat || GoogleAnalyticsCategory.OTHER,
                    payload.gaType,
                    payload.gaLabel,
                ])
                break
            case TrackingActionTypes.SEARCH_RESULTS:
                pCount = payload?.searchResult?.productSearchResult?.pagination?.totalNumberOfResults || 0
                cCount = payload?.searchResult?.categories?.length || 0

                mCount = 0
                if (payload?.searchResult?.modelSearchResult?.[0]) {
                    payload?.searchResult.modelSearchResult.forEach((modelSearchResult) => {
                        mCount += modelSearchResult.totalNumberOfResults
                    })
                }
                window['_paq'].push(['trackEvent', 'SEARCH', type, payload.gaLabel, pCount + cCount + mCount])
                break
            case TrackingActionTypes.AUTOCOMPLETE_SEARCH_RESULTS:
                pCount = payload?.result?.products?.length || 0
                cCount = payload?.result?.categories?.length || 0
                window['_paq'].push(['trackSiteSearch', payload.term, 'SEARCH', pCount + cCount])
                break
            case TrackingActionTypes.HEADER_SEARCH_RESULT_BOX_CLOSED:
                window['_paq'].push(['trackSiteSearch', '__no_action__' + payload.gaLabel, 'SEARCH', 0])
                window['_paq'].push(['trackEvent', 'SEARCH', type, payload.gaLabel, payload.gaVal])
                break
            case TrackingActionTypes.SET_CATEGORY:
                window['_paq'].push(['setEcommerceView', false, false, payload.categoryCode])
                break
            case TrackingActionTypes.ACTIVATE_FILTER:
                window['_paq']?.push(['trackEvent', 'FILTER', payload.id, window.location.href.split('#')[0]])
                break
            default:
                if (
                    window['_paq'] &&
                    `${type}`?.startsWith(`${TrackingActionTypes.HEADER_SEARCH_RESULT_BOX_CLOSED}:`) &&
                    payload
                ) {
                    window['_paq'].push(['trackEvent', deriveArea(payload.gaCat), type, payload.gaLabel, payload.gaVal])
                }
        }
    }
    next(action)
}

export default analytics
