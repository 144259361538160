export enum Icons {
    AngleRight = 'AngleRight',
    AngleDown = 'AngleDown',
    SadTear = 'SadTear',
    Spinner = 'Spinner',
    Times = 'Times',
    Home = 'Home',
    AddressBook = 'AddressBook',
    Edit = 'Edit',
    Check = 'Check',
    CartOutline = 'CartOutline',
    Cart = 'Cart',
    RegUser = 'RegUser',
    User = 'User',
    RegCompass = 'RegCompass',
    RegSquareCheck = 'RegSquareCheck',
    RegSquare = 'RegSquare',
    Sliders = 'Sliders',
    Share = 'Share',
    AngleLeft = 'AngleLeft',
    SignOutAlt = 'SignOutAlt',
    Envelope = 'Envelope',
    Bars = 'Bars',
    Search = 'Search',
    Bullhorn = 'Bullhorn',
    CaretUp = 'CaretUp',
    CaretDown = 'CaretDown',
    AngleUp = 'AngleUp',
    Recycle = 'Recycle',
    UserLock = 'UserLock',
    Truck = 'Truck',
    AddressCard = 'AddressCard',
    CalendarAlt = 'CalendarAlt',
    RegCreditCard = 'RegCreditCard',
    CartArrowDown = 'CartArrowDown',
    History = 'History',
    VacuumCleaner = 'VacuumCleaner',
    PrinterFill = 'PrinterFill',
    RegClock = 'RegClock',
    MapMarkerAlt = 'MapMarkerAlt',
    PiggyBank = 'PiggyBank',
    MagnifyingGlassPlus = 'MagnifyingGlassPlus',
    TriangleExclamation = 'TriangleExclamation',
    Trash = 'Trash',
    Plus = 'Plus',
    Minus = 'Minus',
    CircleCheck = 'CircleCheck',
    TimesCircle = 'TimesCircle',
    Clock = 'Clock',
    RegCircleDot = 'RegCircleDot',
    RegCircle = 'RegCircle',
    ShoppingBasket = 'ShoppingBasket',
    RegCirclePause = 'RegCirclePause',
    RegCirclePlay = 'RegCirclePlay',
    RegCircleStop = 'RegCircleStop',
    SyncAlt = 'SyncAlt',
    Globe = 'Globe',
    Phone = 'Phone',
    House = 'House',
    RegCommentAlt = 'RegCommentAlt',
    UnlockKeyhole = 'UnlockKeyhole',
    DoorOpen = 'DoorOpen',
    RegHeart = 'RegHeart',
    Pencil = 'Pencil',
    Reply = 'Reply',
    UserCircle = 'UserCircle',
    QuoteRight = 'QuoteRight',
    CircleInfo = 'CircleInfo',
    Circle = 'FaCircle',
    Dot = 'Dot',
    DotFill = 'DotFill',
    OutlineNumbers = 'OutlineNumbers',
    CirclePlus = 'CirclePlus',
    CircleMinus = 'CircleMinus',
}
