import { Logger } from '@spa-core/logger'
import net from '@spa-core-js/services/networkSvc'
import { ActionTypes, CATEGORY_CACHE_TTL_SECONDS, FetchCategoryMode } from '@spa-core/store/categories/constants'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ActionTypes as ProductActionTypes } from '../products/constants'
import { SlimProduct } from '../products/interfaces'
import { parseProduct, parseSlimProduct } from '../products/utils'
import { SessionConfig } from '../app/interfaces'
import { selectSessionConfig } from '../utils'
import { setAppendingProducts, setLoading } from './actions'
import {
    FetchCategoryPayload,
    SubcategoryNodeResponse,
    FetchBrandsListCategoriesPayload,
    FecthModelsListcategoriesPayload,
    BrandsListCategory,
    ModelsListCategory,
} from './interfaces'
import { TrackFetchedCategoryPayload } from '../../tracking/interfaces'
import { parseCategoryProductsForTracking } from '../../tracking/utils'
import { TrackingActionTypes } from '../../tracking/constants'
import { redirectTo } from '../navigation/actions'

export function* fetchCategory({ payload }: any) {
    const {
        categoryCode,
        sortCode,
        facetQuery,
        page,
        callback,
        slimApi,
        headerNodes,
        show404ForEmptyResults = false,
        onlyUpdateCategory = false,
        pageSize = 20,
        mode = FetchCategoryMode.REFRESH,
    }: FetchCategoryPayload = payload

    if (mode === FetchCategoryMode.APPEND) {
        yield put(setAppendingProducts(true))
    } else {
        yield put(setLoading(true))
    }

    const sessionConfig: SessionConfig = yield select(selectSessionConfig)

    let data: string = ''
    if (sortCode) {
        data = `sortCode=${sortCode}&`
    }
    if (facetQuery) {
        data += `q=${facetQuery}&`
    }
    if (page) {
        data += `page=${page}&`
    }
    if (slimApi) {
        data += 'slim=true&'
    }
    if (pageSize) {
        data += `pageSize=${pageSize}`
    }
    const url: string = `${sessionConfig.urlPrefix}/rest/v2/categories/${categoryCode}/products?includeSliderScale=true&${data}`
    try {
        const result = yield call(() =>
            net.get(url, {
                cache: 'none',
            }),
        )
        if (
            show404ForEmptyResults &&
            (!result.results || result.results.length === 0) &&
            (!result.subCategories || result.subCategories.length === 0)
        ) {
            /**
             * No results
             */
            yield put(redirectTo({ url: '/notFound/c_' + categoryCode }))
        } else {
            if (result.results && result.results.length > 0 && (sortCode === 'price-asc' || sortCode === 'price-desc')) {
                result.results.sort((a, b) => {
                    const aPrice = a.discountedPrice ? a.discountedPrice : a.price
                    const bPrice = b.discountedPrice ? b.discountedPrice : b.price
                    if (sortCode === 'price-asc') {
                        return aPrice - bPrice
                    }
                    return bPrice - aPrice
                })
            }
            if (result.results) {
                const slimProducts: SlimProduct[] = [
                    ...result.results,
                    ...(result?.subCategories?.map(({ productsForDisplay }) => productsForDisplay?.results) || []).flat(),
                ]
                if (slimApi) {
                    yield put({
                        type: ProductActionTypes.FETCHED_SLIM_PRODUCTS,
                        payload: {
                            slimProducts: slimProducts.map((slimProduct) =>
                                parseSlimProduct(slimProduct, sessionConfig.themeResourcePath),
                            ),
                            mode,
                        },
                    })
                } else {
                    yield put({
                        type: ProductActionTypes.FETCHED_PRODUCTS,
                        payload: {
                            products: result.results.map(parseProduct),
                        },
                    })
                }
            }
            yield put({
                type: ActionTypes.SET_CATEGORY_DATA_IN_STORE,
                payload: {
                    categoryCode,
                    result,
                    onlyUpdateCategory,
                    headerNodes,
                    mode,
                },
            })
        }
        if (callback) callback()
        yield new Promise((res) => setTimeout(res, CATEGORY_CACHE_TTL_SECONDS * 1000))
        const trackingPayload: TrackFetchedCategoryPayload = {
            itemListId: categoryCode,
            itemListName: result.categoryName,
            items: parseCategoryProductsForTracking(sessionConfig.b2bMode, result.results, result.subCategories),
        }
        yield put({
            type: TrackingActionTypes.FETCHED_CATEGORIES,
            payload: trackingPayload,
        })
        yield put({
            type: ActionTypes.CLEAR_CATEGORY_DATA,
        })
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchFilters({ payload }: any) {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v2/categories/${payload.categoryCode}/products?includeSliderScale=true&sortCode=freeCategoriesSort&slim=true&page=1000`
    try {
        const result = yield call(() =>
            net.get(url, {
                cache: 'none',
            }),
        )

        const sliders = result.sliders || []
        const facets = result.facets || []

        yield put({
            type: ActionTypes.SET_FILTERS,
            payload: {
                sliders,
                facets,
                categoryCode: result.categoryCode,
            },
        })
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchSubcategories({ payload }: any) {
    const { categoryCode } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v2/categories/nodes?codes=${categoryCode}`
    try {
        const result: SubcategoryNodeResponse = yield call(() =>
            net.get(url, {
                cache: 'none',
            }),
        )

        if (result) {
            yield put({
                type: ActionTypes.FETCHED_SUBCATEGORIES,
                payload: {
                    subcategories: result,
                    categoryCode,
                },
            })
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchBrandsListCategories({ payload }: any) {
    let { categoryCode }: FetchBrandsListCategoriesPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)

    if (typeof categoryCode === 'undefined') {
        Logger.error('Category code not set, using t5')
        categoryCode = 't5'
    }

    const url: string = `${sessionConfig.urlPrefix}/rest/v1/brandListForCategory/${categoryCode}`

    try {
        const result: BrandsListCategory = yield call(() =>
            net.get(url, {
                cache: 'none',
            }),
        )

        if (result) {
            yield put({
                type: ActionTypes.FETCHED_BRANDSLIST_CATEGORIES,
                payload: {
                    brands: result.brands,
                    categoryName: result.categoryName,
                    categoryCode: categoryCode,
                    metaTitle: result.metaTitle,
                    metaDescription: result.metaDescription,
                },
            })
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fecthModelsListcategories({ payload }: any) {
    const { categoryCode, brand }: FecthModelsListcategoriesPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/categoryModelList/${categoryCode}/${brand}`
    try {
        const result: ModelsListCategory = yield call(() =>
            net.get(url, {
                cache: 'none',
            }),
        )

        if (result) {
            yield put({
                type: ActionTypes.FETCHED_MODELSLIST_CATEGORIES,
                payload: {
                    models: result.models,
                    categoryName: result.categoryName,
                    brandName: result.brandName,
                    categoryCode: categoryCode,
                    metaTitle: result.metaTitle,
                    metaDescription: result.metaDescription,
                    preamble: result.preamble,
                },
            })
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export const watchers = [
    takeLatest(ActionTypes.FETCH_CATEGORY, fetchCategory),
    takeLatest(ActionTypes.FETCH_FILTERS, fetchFilters),
    takeLatest(ActionTypes.FETCH_SUBCATEGORIES, fetchSubcategories),
    takeLatest(ActionTypes.FETCH_BRANDSLIST_CATEGORIES, fetchBrandsListCategories),
    takeLatest(ActionTypes.FETCH_MODELSLIST_CATEGORIES, fecthModelsListcategories),
]
