import React from 'react'
import Link from '@ui-elem/Link'
import { NavigationTreeNodeItems } from '@spa-core/store/categories/interfaces'

enum Style {
    DESKTOP_FOOTER = 'desktop_footer',
    DESKTOP_FOOTER_NODES = 'desktop_footer_nodes',
    DESKTOP_INDIVIDUAL_FOOTER_NODE = 'desktop_inidividual_footer_node',
}
interface Props {
    footerNavigationNodes: NavigationTreeNodeItems[]
}

const FooterLinkForDesktop = ({ footerNavigationNodes }: Props): React.ReactNode => (
    <div className={`${Style.DESKTOP_FOOTER} w-full md:px-4 md:pb-6 hidden md:block`}>
        <ul className={`${Style.DESKTOP_FOOTER_NODES} m-0`}>
            {footerNavigationNodes.map((item: NavigationTreeNodeItems) =>
                item.nodes?.map((innerItem: NavigationTreeNodeItems) => {
                    if (!innerItem.uid) return null
                    return (
                        <li key={'p_' + innerItem.uid} className={`${Style.DESKTOP_INDIVIDUAL_FOOTER_NODE} px-2`}>
                            <h4 className={'font-bold my-4'}>{innerItem.title}</h4>
                            <ul className="m-0" key={'footer_ul_' + innerItem.uid}>
                                {/* Lists footer menu items */}
                                {innerItem.nodes
                                    .filter((link: NavigationTreeNodeItems) => link.url)
                                    .map((link: NavigationTreeNodeItems) => (
                                        <li key={link.uid} className={'text-sm e2e-ver-url' + link.url.replace('/', '-')}>
                                            <Link
                                                to={link.url}
                                                external={link.external}
                                                target={link.target}
                                                linkTitle={link.title}
                                            />
                                        </li>
                                    ))}
                                {/* Adds content driven items */}
                                {innerItem.image ? (
                                    <li>
                                        <img alt={innerItem.image.altText} src={innerItem.image.url} />
                                    </li>
                                ) : null}
                                {innerItem.description ? (
                                    <li>
                                        <div dangerouslySetInnerHTML={{ __html: innerItem.description }} />
                                    </li>
                                ) : null}
                            </ul>
                        </li>
                    )
                }),
            )}
        </ul>
    </div>
)

export default FooterLinkForDesktop
