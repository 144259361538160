import { Action } from '../interfaces'
import { Price } from '../products/interfaces'
import { ActionTypes, PurchaseType } from './constants'
import { CartStore, Recommended, SetCartDataPayload } from './interfaces'

export const INITIAL_STATE: CartStore = {
    currentlyAddingToCart: {},
    updatingCart: false,
    entries: [],
    initialState: true,
    totalItems: 0,
    cartCode: undefined,
    paymentProviderOrderId: undefined,
    totalTax: undefined,
    subTotal: undefined,
    totalIncVatPrice: undefined,
    totalExVatPrice: undefined,
    deliveryCost: undefined,
    deliveryCostExVatPrice: undefined,
    deliveryCostIncVatPrice: undefined,
    paymentCost: undefined,
    paymentCostExVatPrice: undefined,
    paymentCostIncVatPrice: undefined,
    appliedOrderPromotions: [],
    orderDiscounts: undefined,
    appliedProductPromotions: [],
    subscriptionThresholdProductCount: undefined,
    isSubscriptionEligible: false,
    isCheckoutEligible: false,
    enableOneBuyMode: false,
    purchaseType: undefined,
    selectedSubscriptionInterval: undefined,
    enableSubscriptionMode: false,
    onlyFreeItemsInCart: false,
    recommended: undefined,
    showRestoreCart: false,
    restoringAbandonedCart: false,
    restoreCartTotalItems: 0,
    deletingCartRowEntries: {},
    replacingProducts: {},
    applyCampaign: undefined,
    applyingCampaign: false,
    subscriptionOptions: [],
}

const getPrice = (price: Price): Price =>
    price || {
        currencyIso: '',
        longFormattedValue: '',
        formattedValue: '',
        value: 0,
        shortFormattedValue: '',
    }

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): CartStore => {
    switch (type) {
        case ActionTypes.SET_CART_DATA_IN_STORE: {
            const { cartData, abandonedCartData, enableOneBuyMode, isSubscriptionEligible }: SetCartDataPayload = payload
            let totalItems: number = 0
            cartData.entries.forEach(({ quantity }) => {
                totalItems += quantity
            })

            const purchaseInfo: any = {}
            if (enableOneBuyMode || !isSubscriptionEligible) {
                purchaseInfo.purchaseType = PurchaseType.ONE_TIME
                purchaseInfo.selectedSubscriptionInterval = undefined
            }
            if (cartData.selectedSubscriptionInterval) {
                purchaseInfo.purchaseType = PurchaseType.SUBSCRIPTION
                purchaseInfo.selectedSubscriptionInterval = {
                    code: cartData.selectedSubscriptionInterval.code,
                    name: cartData.selectedSubscriptionInterval.name,
                }
            }

            const onlyFreeItemsInCart: boolean = state.totalItems > 0 && state.totalIncVatPrice.value === 0
            const updatedAbandonedCart = abandonedCartData ? abandonedCartData : state.abandonedCartData
            return {
                ...state,
                initialState: false,
                totalItems,
                cartCode: cartData.code,
                entries: cartData.entries,
                paymentProviderOrderId: cartData.paymentProviderOrderId,
                totalTax: getPrice(cartData.totalTax),
                subTotal: getPrice(cartData.subTotal),
                totalIncVatPrice: getPrice(cartData.totalIncVatPrice),
                totalExVatPrice: getPrice(cartData.totalExVatPrice),
                deliveryCost: getPrice(cartData.deliveryCost),
                deliveryCostExVatPrice: getPrice(cartData.deliveryCostExVatPrice),
                deliveryCostIncVatPrice: getPrice(cartData.deliveryCostIncVatPrice),
                paymentCost: getPrice(cartData.paymentCost),
                paymentCostExVatPrice: getPrice(cartData.paymentCostExVatPrice),
                paymentCostIncVatPrice: getPrice(cartData.paymentCostExVatPrice),
                orderDiscounts: getPrice(cartData.orderDiscounts),
                subscriptionThresholdProductCount: getPrice(cartData.subscriptionThresholdProductCount),
                appliedOrderPromotions: cartData.appliedOrderPromotions || [],
                appliedProductPromotions: cartData.appliedProductPromotions || [],
                isSubscriptionEligible: cartData.isSubscriptionEligible,
                isCheckoutEligible: cartData.isCheckoutEligible,
                onlyFreeItemsInCart,
                abandonedCartData: updatedAbandonedCart,
                ...purchaseInfo,
            }
        }
        case ActionTypes.SET_DELETE_CART_ROW_ENTRY:
            return {
                ...state,
                deletingCartRowEntries: {
                    ...state.deletingCartRowEntries,
                    [payload.entryNumber]: payload.deleting,
                },
            }
        case ActionTypes.FETCHED_RECOMMENDED_CART: {
            const { recommendedCart, potentialBuyOption }: Recommended = payload
            return {
                ...state,
                recommended: {
                    recommendedCart,
                    potentialBuyOption,
                },
            }
        }
        case ActionTypes.SET_UPDATING_CART: {
            const { updatingCart } = payload
            return {
                ...state,
                updatingCart,
            }
        }
        case ActionTypes.SET_CURRENTLY_ADDING_TO_CART:
            return {
                ...state,
                currentlyAddingToCart: {
                    ...state.currentlyAddingToCart,
                    [payload.productCode]: payload.value,
                },
            }
        case ActionTypes.SET_SHOW_RESTORE_CART:
            return {
                ...state,
                showRestoreCart: payload.showRestoreCart,
            }
        case ActionTypes.SET_RESTORING_ABANDONED_CART:
            return {
                ...state,
                restoringAbandonedCart: payload.restoringAbandonedCart,
            }
        case ActionTypes.SET_RESTORE_CART_TOTAL_ITEMS:
            return {
                ...state,
                restoreCartTotalItems: payload.totalItems,
            }
        case ActionTypes.SET_REPLACE_PRODUCT_LOADING:
            return {
                ...state,
                replacingProducts: {
                    ...state.replacingProducts,
                    [payload.productCode]: payload.loading,
                },
            }
        case ActionTypes.APPLIED_CAMPAIGN:
            return {
                ...state,
                applyCampaign: payload,
            }
        case ActionTypes.SET_APPLYING_CAMPAIGN:
            return {
                ...state,
                applyingCampaign: payload,
            }
        case ActionTypes.FETCHED_SUBSCRIPTION_OPTIONS:
            return {
                ...state,
                subscriptionOptions: payload,
            }
        default:
            return state
    }
}
