import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin'
import Core from '@adyen/adyen-web/dist/types/core/core'
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types'
import { Store } from '@spa-core/store'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { fetchAdyenPaymentSession, finalizeAdyenPaymentSession } from '@spa-core/store/checkout/actions'
import { NAME as checkoutReducerName } from '@spa-core/store/checkout/constants'
import { AdyenPaymentSession } from '@spa-core/store/checkout/interfaces'
import { t } from '@spa-core/locale'
import Button, { ButtonColors } from '@ui-elem/Button/Button'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createSelector } from 'reselect'
import { Logger } from '@spa-core/logger'
import Spinner from '@ui-elem/Spinner'

export const ADYEN_PAYMENT_PROVIDER_CODE: string = 'adyen_unified_payment_mode'
export const ADYEN_SESSION_CONFIG_PAYMENT_PROVIDER: string = 'ADYEN'

type ComponentState = {
    adyenPaymentSession: AdyenPaymentSession
    placeOrderInProgress?: boolean
    orderConfirmationRedirectURL?: string
}

type Props = {
    unlockCheckout: () => void
    sessionConfig: SessionConfig
    orderId?: string
}

const AdyenPayment: React.FC<Props> = ({ unlockCheckout, sessionConfig, orderId }) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const [adyenCheckoutLoading, setAdyenCheckoutLoading] = useState(true)
    const [redirectResultParam, setRedirectResultParam] = useState<string | undefined>(undefined)
    const [sessionIdParam, setSessionIdParam] = useState<string | undefined>(undefined)

    const checkoutStore = ({ reducers }: Store) => reducers[checkoutReducerName]
    const selector = createSelector(
        [checkoutStore],
        (checkout): ComponentState => ({
            adyenPaymentSession: checkout.adyenPaymentSessionRecords[orderId],
            placeOrderInProgress: checkout.placingOrder,
            orderConfirmationRedirectURL: checkout.orderConfirmationRedirectURL || `/checkout/step3/${orderId}`,
        }),
    )
    const { adyenPaymentSession, placeOrderInProgress, orderConfirmationRedirectURL }: ComponentState = useSelector(selector)

    const checkoutConfig: CoreOptions | null = useMemo(() => {
        if (!adyenPaymentSession) {
            return
        }
        const { env, countryCode, sessionId, sessionData, locale, clientKey } = adyenPaymentSession
        return {
            environment: env,
            countryCode,
            clientKey,
            showPayButton: true,
            session: {
                id: sessionId,
                sessionData,
            },
            locale,
            translations: {
                [locale]: {
                    'creditCard.expiryDateField.title': t('adyen.creditCard.expiryDateField.title'),
                    'trustly.description1': t('adyen.trustly.description1'),
                    'trustly.description2': t('adyen.trustly.description2'),
                    'trustly.descriptor': t('adyen.trustly.descriptor'),
                },
            },
            onPaymentCompleted: (result, _component) => {
                if (result.resultCode === 'Authorised') {
                    dispatch(finalizeAdyenPaymentSession(result.resultCode))
                    setTimeout(
                        () => (window.location.href = `${sessionConfig.urlPrefix}${orderConfirmationRedirectURL}`),
                        redirectResultParam ? 10 : 2000,
                    )
                }
            },
            onPaymentFailed: (result, component) => {
                console.info(result, component)
            },
            onError: (error, component) => {
                Logger.error('AdyenPayment', checkoutConfig, error.name, error.message, error.stack, component)
            },
        }
    }, [adyenPaymentSession, dispatch, finalizeAdyenPaymentSession, redirectResultParam])

    const paymentRef = useRef(null)
    const threeDSRef = useRef(null)

    let dropIn: DropinElement

    const adyenCheckout = useMemo(() => {
        if (checkoutConfig) {
            return AdyenCheckout(checkoutConfig)
        }
        return null
    }, [checkoutConfig])

    useEffect(() => {
        if (location.hash && location.hash.split('?').length > 1) {
            const queryParameters = new URLSearchParams(location.hash.split('?')[1])
            const redirectResultParam = queryParameters.get('redirectResult')
            const sessionIdParam = queryParameters.get('sessionId')
            if (sessionIdParam === adyenPaymentSession?.sessionId && redirectResultParam) {
                setRedirectResultParam(redirectResultParam)
                setSessionIdParam(sessionIdParam)
            }
        }
    }, [location.search, adyenPaymentSession?.sessionId])

    const submitAdyenRedirectResult = useCallback(
        (adyen: Core) => {
            if (redirectResultParam && sessionIdParam) {
                adyen.submitDetails({ details: { redirectResult: redirectResultParam } })
            }
        },
        [redirectResultParam, sessionIdParam],
    )

    const initiateDropIn = useCallback(async () => {
        setAdyenCheckoutLoading(true)
        if (paymentRef.current && adyenCheckout) {
            const adyen = await adyenCheckout
            dropIn = adyen.create('dropin', {
                onReady() {
                    setAdyenCheckoutLoading(false)
                },
            })
            dropIn.mount(paymentRef.current)
            submitAdyenRedirectResult(adyen)
        }
    }, [adyenCheckout, paymentRef.current, submitAdyenRedirectResult])

    useEffect(() => {
        if (checkoutConfig) {
            initiateDropIn()
        }
    }, [initiateDropIn, adyenPaymentSession, checkoutConfig])

    useEffect(() => {
        if (orderId && !adyenPaymentSession) {
            dispatch(fetchAdyenPaymentSession(orderId))
        }
    }, [orderId, adyenPaymentSession])

    return (
        <>
            <div className="flex flex-col md:flex-row place-order-section">
                <div className="w-full md:w-2/3 mb-3 md:mb-0 mr-0 md:mr-3">
                    <div className={'checkout-paymentmethod'}>
                        <div className={'dropin-payment'} ref={paymentRef} />
                    </div>
                    <div ref={threeDSRef} />
                    {(placeOrderInProgress || adyenCheckoutLoading) && <Spinner className="mt-4" />}
                </div>
                <div id={'placeOrder'} className="w-full mb-3 md:mb-0 mt-3 md:mt-0 md:w-1/3 order-first md:order-last">
                    <Button
                        className="px-6 py-4"
                        fluid={true}
                        onClick={unlockCheckout}
                        buttonColor={ButtonColors.SECONDARY}
                        buttonText={t('checkout.button.changeOrderDetails')}
                    />
                </div>
            </div>
        </>
    )
}

export default AdyenPayment
