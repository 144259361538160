export const NAME = 'app'

export const ActionTypes = {
    LOG_IN: `${NAME}/LOG_IN`,
    SET_LOGGING_IN: `${NAME}/SET_LOGGING_IN`,
    SET_LOG_IN_ERROR_MESSAGE: `${NAME}/SET_LOG_IN_ERROR_MESSAGE`,
    FETCH_PROMOTIONS: `${NAME}/FETCH_PROMOTIONS`,
    FETCHED_PROMOTIONS: `${NAME}/FETCHED_PROMOTIONS`,
    UPDATE_SESSION_CONFIG: `${NAME}/UPDATE_SESSION_CONFIG`,
    SET_UPDATING_SESSION_CONFIG: `${NAME}/SET_UPDATING_SESSION_CONFIG`,
    SET_CONFIG: `${NAME}/SET_CONFIG`,
    PRODUCT_DETAILS_VIEWED: `${NAME}/PRODUCT_DETAILS_VIEWED`,
    PRODUCT_INFORMATION_EXPANDED: `${NAME}/PRODUCT_INFORMATION_EXPANDED`,
    PRODUCT_SPECIFICATION_EXPANDED: `${NAME}/PRODUCT_SPECIFICATION_EXPANDED`,
    SET_PURCHASE_QUANTITY: `${NAME}/SET_PURCHASE_QUANTITY`,
    INIT_APPLICATION: `${NAME}/INIT_APPLICATION`,
    FETCH_INIT_CONFIG: `${NAME}/FETCH_INIT_CONFIG`,
    SET_CUSTOMER_TYPE_CHANGE_WARNING: `${NAME}/SET_CUSTOMER_TYPE_CHANGE_WARNING`,
    CHECK_CUSTOMER_TO_BE_LOGGED_OFF: `${NAME}/CHECK_CUSTOMER_TO_BE_LOGGED_OFF`,
    LOG_OUT_SWITCH_CUSTOMER_TYPE_OR_CHANGE_LANGUAGE: `${NAME}/LOG_OUT_CUSTOMER_AND_SWITCH_CUSTOMER_TYPE`,
    SET_COLLAPSABLE_PANEL_EXPANDED: `${NAME}/SET_COLLAPSABLE_PANEL_EXPANDED`,
    FETCH_PERSONALIZED_DELIVERY_OPTIONS: `${NAME}/FETCH_PERSONALIZED_DELIVERY_OPTIONS`,
    FETCHED_PERSONALIZED_DELIVERY_OPTIONS: `${NAME}/FETCHED_PERSONALIZED_DELIVERY_OPTIONS`,
    FETCH_ACCOUNT_PROFILE: `${NAME}/FETCH_ACCOUNT_PROFILE`,
    FETCHED_ACCOUNT_PROFILE: `${NAME}/FETCHED_ACCOUNT_PROFILE`,
    SUBSCRIBE_TO_NEWSLETTER: `${NAME}/SUBSCRIBE_TO_NEWSLETTER`,
    RESET_SUBSCRIBE_TO_NEWSLETTER_FORM: `${NAME}/RESET_SUBSCRIBE_TO_NEWSLETTER_FORM`,
    SUBSCRIBE_TO_NEWSLETTER_RESULT: `${NAME}/SUBSCRIBE_TO_NEWSLETTER_RESULT`,
    REQUEST_PASSWORD_EMAIL: `${NAME}/REQUEST_PASSWORD_EMAIL`,
    REQUEST_PASSWORD_CUSTOMERNUMBER: `${NAME}/REQUEST_PASSWORD_CUSTOMERNUMBER`,
    RESET_REQUEST_PASSWORD_FORM: `${NAME}/RESET_REQUEST_PASSWORD_FORM`,
    REQUEST_PASSWORD_RESULT: `${NAME}/RESET_REQUEST_PASSWORD_RESULT`,
    REFRESH_SITEMAP: `${NAME}/REFRESH_SITEMAP`,
    REFRESHING_SITEMAP: `${NAME}/REFRESHING_SITEMAP`,
    FETCH_ORDER_HISTORY: `${NAME}/FETCH_ORDER_HISTORY`,
    FETCHED_ORDER_HISTORY: `${NAME}/FETCHED_ORDER_HISTORY`,
    SET_FETCHING_ORDER_HISTORY: `${NAME}/SET_FETCHING_ORDER_HISTORY`,
    FETCH_ORDER: `${NAME}/FETCH_ORDER`,
    FETCHED_ORDER: `${NAME}/FETCHED_ORDER`,
    SET_FETCHING_ORDER: `${NAME}/SET_FETCHING_ORDER`,
}

export enum ExternalScriptContentType {
    HTML = 'HTML',
    SCRIPT = 'SCRIPT',
    URLS_AFTER_ORDER = 'URLS_AFTER_ORDER',
}

export enum ExternalScriptEvent {
    ALL_PAGES = 'ALL_PAGES',
    PAGE_VIEW = 'PAGE_VIEW',
    FETCH_ORDER_CONF_DATA = 'FETCH_ORDER_CONF_DATA',
    SPA_PLACING_ORDER = 'SPA_PLACING_ORDER',
    CART_SVC_DATA = 'CART_SVC_DATA',
    CART_SVC_ADD = 'CART_SVC_ADD',
}

export enum CustomerType {
    PRIVATE = 'private',
    COMPANY = 'company',
}

export enum NewsletterSubscriptionStatus {
    NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
    SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS',
    SUBSCRIPTION_FAILED = 'SUBSCRIPTION_FAILED',
}

export enum RequestPasswordStatus {
    NO_REQUEST_PASSWORD = 'NO_REQUEST_PASSWORD',
    REQUEST_PASSWORD_EMAIL_SUCCESS = 'REQUEST_PASSWORD_EMAIL_SUCCESS',
    REQUEST_PASSWORD_EMAIL_FAILED = 'REQUEST_PASSWORD_EMAIL_FAILED',
    REQUEST_PASSWORD_CUSTOMER_NUMBER_SUCCESS = 'REQUEST_PASSWORD_CUSTOMER_NUMBER_SUCCESS',
    REQUEST_PASSWORD_CUSTOMER_NUMBER_FAILED = 'REQUEST_PASSWORD_CUSTOMER_NUMBER_FAILED',
}
