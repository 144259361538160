import classNames from 'classnames'
import React from 'react'
import { svcData as navData } from '@spa-ec-js/services/navigationSvc/navigationSvcMod'
import FooterLinksForDesktop from '@spa-ec/components/Footer/FooterLinksForDesktop'
import FooterLinksForMobile from '@spa-ec/components/Footer/FooterLinksForMobile'
import ContentSlot from '@spa-ec/components/ContentSlot/ContentSlot'
import Newsletter from '@spa-ec/components/NewsLetter/NewsLetter'
import { getComponentTheme } from '@ic-theme'
import { NAME, FooterTheme, CountryCode } from './Footer.theme'
import { NavigationTreeNodeItems } from '@spa-core/store/categories/interfaces'
import SocailMediaLinks from '@spa-ec/components/Footer/SocialMediaLinks'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import FooterProviders from './FooterProviders'
import { Position } from '@spa-core/store/content-slots/constants'

type ComponentState = {
    sessionConfig: SessionConfig
}

const theme: FooterTheme = getComponentTheme<FooterTheme>(NAME, {
    showNewsLetter: true,
    showSocialMediaLinks: false,
    showSocialMediaInCountry: [CountryCode.SE],
})

enum Style {
    FOOTER_CONFIG = 'footer_config',
    FOOTER_LINK = 'footer_links',
}

interface Props {
    pageId: string
}

const FooterLinkForDesktop = ({ pageId }: Props): React.ReactNode => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)
    const countryIso: string = sessionConfig.countryIso.toLowerCase()

    const footerNavigationNodes: NavigationTreeNodeItems[] = navData.footerNodes ? navData.footerNodes : []

    return (
        <footer className={classNames(Style.FOOTER_CONFIG, 'max-w-[1218px] min-h-screen md:min-h-fit m-auto pb-2 print:hidden')}>
            <div className={'flex flex-col md:flex-row w-full print:hidden e2e-ver-footer-nodes'}>
                <div className={classNames(Style.FOOTER_LINK, 'flex-1 md:w-5/6')}>
                    <FooterLinksForDesktop footerNavigationNodes={footerNavigationNodes} />
                    <FooterLinksForMobile footerNavigationNodes={footerNavigationNodes} />
                </div>
                {pageId !== undefined ? (
                    <div className={classNames('w-fit max-w-lg px-2 md:pt-6 md:pr-2 e2e-ver-right-footer-content')}>
                        <ContentSlot key={pageId + '---RightFooter'} pageId={pageId} position={Position.RIGHT_FOOTER} />
                    </div>
                ) : null}
            </div>
            {theme.showNewsLetter ? <Newsletter /> : null}
            <FooterProviders />
            {theme.showSocialMediaLinks && theme.showSocialMediaInCountry.includes(countryIso as CountryCode) ? (
                <SocailMediaLinks />
            ) : null}
        </footer>
    )
}

export default FooterLinkForDesktop
