import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import classnames from 'classnames'
import { removeMessage } from '@spa-core/store/global-messages/actions'
import { MessageLevels } from '@spa-core/store/global-messages/constants'

type Props = {
    id: number | string
    level: string
    title: string
    message: React.ReactNode | string
    openMessage: boolean
}

const Message: React.FC<Props> = ({ id, level, title, message, openMessage }) => {
    const dispatch = useDispatch()

    return (
        <Fragment key={id}>
            <div className={classnames('px-0 py-1 m-0', openMessage ? 'animate_fade_in' : 'animate_fade_out')}>
                <div
                    id={`message_${id}`}
                    className={classnames(
                        'flex round-box items-center justify-between cursor-pointer mb-4',
                        level === MessageLevels.INFO ? 'info_color' : '',
                        level === MessageLevels.ERROR ? 'error_color' : '',
                    )}
                    key={id}
                    onClick={() => {
                        dispatch(removeMessage(id))
                    }}
                    data-testid={`message_${id}`}
                >
                    <div className={'flex p-2'}>
                        <div className="title pr-2">{title}</div>
                        <div className="msg ic-padding-l">{message}</div>
                    </div>
                    <Icon icon={Icons.Times} className={'pr-1'} />
                </div>
            </div>
        </Fragment>
    )
}

export default Message
