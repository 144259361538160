export const NAME = 'checkout'

export const ActionTypes = {
    UPDATE_CHECKOUT_SETTINGS: `${NAME}/UPDATE_CHECKOUT_SETTINGS`,
    FETCH_CHECKOUT_SETTINGS: `${NAME}/FETCH_CHECKOUT_SETTINGS`,
    FETCHED_CHECKOUT_SETTINGS: `${NAME}/FETCHED_CHECKOUT_SETTINGS`,
    SAVE_CHECKOUT_FORM_DATA: `${NAME}/SAVE_CHECKOUT_FORM_DATA`,
    SAVE_CHECKOUT_FORM_DATA_BATCH: `${NAME}/SAVE_CHECKOUT_FORM_DATA_BATCH`,
    VALIDATE_AND_SAVE_CHECKOUT_FORM_DATA: `${NAME}/VALIDATE_AND_SAVE_CHECKOUT_FORM_DATA`,
    VALIDATE_AND_SAVE_CHECKOUT_FORM_DATA_BATCH: `${NAME}/VALIDATE_AND_SAVE_CHECKOUT_FORM_DATA_BATCH`,
    RESET_CHECKOUT_FORM_DATA: `${NAME}/RESET_CHECKOUT_FORM_DATA`,
    FETCH_ORDER_CONFIRMATION: `${NAME}/FETCH_ORDER_CONFIRMATION`,
    FETCHED_ORDER_CONFIRMATION: `${NAME}/FETCHED_ORDER_CONFIRMATION`,
    NOT_YOU: `${NAME}/NOT_YOU`,
    CHECK_PAYMENT_MODE: `${NAME}/CHECK_PAYMENT_MODE`,
    CHECK_DELIVERY_MODE: `${NAME}/CHECK_DELIVERY_MODE`,
    FETCH_PICKUP_POINTS: `${NAME}/FETCH_PICKUP_POINTS`,
    FETCHED_PICKUP_POINTS: `${NAME}/FETCHED_PICKUP_POINTS`,
    SET_FETCHING_PICKUP_POINTS: `${NAME}/SET_FETCHING_PICKUP_POINTS`,
    SET_DELIVERY_MODE_ERROR_MESSAGE: `${NAME}/SET_DELIVERY_MODE_ERROR_MESSAGE`,
    PLACE_ORDER: `${NAME}/PLACE_ORDER`,
    SET_PLACING_ORDER: `${NAME}/SET_PLACING_ORDER`,
    CHECK_SEPARATE_SHIPMENT_ADDRESS: `${NAME}/CHECK_SEPARATE_SHIPMENT_ADDRESS`,
    UPDATE_CHECKOUT_FORM: `${NAME}/UPDATE_CHECKOUT_FORM`,
    UPDATE_CHECKOUT_FORM_FIELD: `${NAME}/UPDATE_CHECKOUT_FORM_FIELD`,
    DO_ADDRESS_LOOKUP: `${NAME}/DO_ADDRESS_LOOKUP`,
    SET_DO_ADDRESS_LOOKUP_LOADING: `${NAME}/SET_DO_ADDRESS_LOOKUP_LOADING`,
    SET_DO_ADDRESS_LOOKUP_ERROR: `${NAME}/SET_DO_ADDRESS_LOOKUP_ERROR`,
    SET_SELECTED_DELIVERY_ADDRESS: `${NAME}/SET_SELECTED_DELIVERY_ADDRESS`,
    SET_SELECTED_PAYMENT_MODE: `${NAME}/SET_SELECTED_PAYMENT_MODE`,
    SET_DID_ADDRESS_LOOKUP: `${NAME}/SET_DID_ADDRESS_LOOKUP`,
    SET_DISABLED_FIELDS: `${NAME}/SET_DISABLED_FIELDS`,
    FETCHED_ADYEN_PAYMENT_SESSION: `${NAME}/FETCHED_ADYEN_PAYMENT_SESSION`,
    FETCH_ADYEN_PAYMENT_SESSION: `${NAME}/ADYEN_PAYMENT_SESSION`,
    FINALIZE_ADYEN_PAYMENT_SESSION: `${NAME}/FINALIZE_ADYEN_PAYMENT_SESSION`,
    TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED: `${NAME}/TOGGLE_ADYEN_PAYMENT_SESSION_LOAD_FAILED`,
    SET_PLACED_ORDER_ID: `${NAME}/SET_PLACED_ORDER_ID`,
    SET_CHECKOUT_LOCKED: `${NAME}/SET_CHECKOUT_LOCKED`,
    FETCH_DELIVERY_MODES: `${NAME}/FETCH_DELIVERY_MODES`,
    FETCHED_DELIVERY_MODES: `${NAME}/FETCHED_DELIVERY_MODES`,
    CLEAR_CHECKOUT_FORM_ERROR_MESSAGE: `${NAME}/CLEAR_CHECKOUT_FORM_ERROR_MESSAGE`,
}

export enum PaymentModeCode {
    INVOICE = 'invoice',
    PAPER_INVOICE = 'paper_invoice',
    CREDIT_CARD = 'creditcard',
    PAYPAL = 'paypal',
    POST = 'post',
    E_INVOICE = 'einvoice',
}

export enum AccountType {
    CONSUMER = 'consumer',
    CORPORATE = 'corporate',
}

export enum CheckoutFormKey {
    LINE1 = 'line1',
    LINE2 = 'line2',
    TOWN_CITY = 'townCity',
    POSTCODE = 'postcode',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    CAREOF = 'careof',
    COUNTRY = 'country',
    COUNTRY_ISO = 'countryIso',
    PHONE = 'phone',
    CELLPHONE = 'cellphone',
    EMAIL = 'email',
    ACCESS_CODE = 'accessCode',
    PAYMENT_METHOD = 'paymentMethod',
    SHIPMENT_METHOD = 'shipmentMethod',
    SECURITY_CODE = 'securityCode',
    VOUCHER_CODE = 'voucherCode',
    INVOICE_EMAIL = 'invoiceEmail',
    REMEMBER_ME = 'rememberMe',
    ADDRESS_ID = 'addressId',
    ACCOUND_TYPE = 'accountType',
    COMPANY_TYPE = 'companyType',
    SMS_NOTIFICATION = 'smsNotification',
    PERSONAL_IDENTITY_NUMBER = 'personalIdentityNumber',
    BIRTH_DATE = 'birthDate',
    OTHER = 'other',
    PICKUP_POINT_ID = 'pickupPointId',
    PICKUP_POINT_NAME = 'pickupPointName',
    COMPANY_NAME = 'companyName',
    COMPANY_CONTACT_PERSON = 'companyContactPerson',
    PURCHASE_REFERENCE_FOR_ORDER = 'purchaseReferenceForOrder',
    ORGANIZATION_NUMBER = 'organizationNumber',
    DIFFERENT_DELIVERY_ADDRESS = 'differentDeliveryAddress',
    SHIPPING_ADDRESS_FIRSTNAME = 'shippingAddressFirstname',
    SHIPPING_ADDRESS_SURNAME = 'shippingAddressSurname',
    SHIPPING_ADDRESS_CAREOF = 'shippingAddressCareof',
    SHIPPING_ADDRESS_COMPANY_NAME = 'shippingAddressCompanyName',
    SHIPPING_ADDRESS_COMPANY_CONTACT_PERSON = 'shippingAddressCompanyContactPerson',
    SHIPPING_ADDRESS_LINE1 = 'shippingAddressLine1',
    SHIPPING_ADDRESS_LINE2 = 'shippingAddressLine2',
    SHIPPING_ADDRESS_POSTCODE = 'shippingAddressPostcode',
    SHIPPING_ADDRESS_CITY = 'shippingAddressCity',
    SHIPPING_ADDRESS_COUNTRY = 'shippingAddressCountry',
    SHIPPING_ADDRESS_ACCESS_CODE = 'shippingAddressAccessCode',
    SHIPPING_ADDRESS_CELLPHONE = 'shippingAddressCellphone',
    SHIPPING_ADDRESS_EMAIL = 'shippingAddressEmail',
    TERMS_CHECK = 'termsCheck',
    RIGHTS_OF_REGRETS_CHECK = 'rightsOfRegretsCheck',
    GDPR = 'gdpr',
    PERSONAL_NUMBER_LOOKED_UP = 'personalNumberLookedUp',
    POSTAL_CODE_RELOAD = 'postalCodeReload',
    CHECKOUT_FORM = 'checkoutForm',
}

export enum CheckoutSettingsKey {
    CHECKOUT_FORM = 'checkoutForm',
    ADDRESS_BOOK = 'addressBook',
    PERSONAL_NUMBER_FORMAT_SUGGESTION = 'personalNumberFormatSuggestion',
    ORGANIZATION_NUMBER_FORMAT_SUGGESTION = 'organizationNumberFormatSuggestion',
    MOBILE_FORMAT_SUGGESION = 'mobileFormatSuggestion',
    POST_CODE_FORMAT_SUGGESTION = 'postCodeFormatSuggestion',
    COMPANY_TYPE = 'companyType',
    SLECTED_DELIVERY_MODE = 'selectedDeliveryMode',
    SELECTED_PAYMENT_MODE = 'selectedPaymentMode',
    SHOW_PREFILLED_USER_BLOCK = 'showPrefilledUserBlock',
    SHOW_NOT_YOU_LINK = 'showNotYouLink',
    ANONYMOUS_FORM = 'anonymousForm',
    SHOW_PERSONAL_IDENTITY_NUMBER = 'showPersonalIdentityNumber',
    SHOW_BIRTH_DATE = 'showBirthDate',
    SHOW_SMS_NOTIFICATION = 'showSMSNotification',
    SHOW_TERMS_AND_CONDITIONS = 'showTermsAndConditions',
    SHOW_RIGHTS_OF_REGRET = 'showRightsOfRegret',
    SHOW_GDPR = 'showGDPR',
    SHOW_ORGANIZATION_NUMBER = 'showOrganizationNumber',
    ENABLE_ADDRESS_LOOKUP = 'enableAddressLookup',
    ENABLE_SHIPMENT_LOOKUP = 'enableShipmentLookup',
    SHOW_SEPARATE_DELIVERY_ADDRESS = 'showSeparateDeliveryAddress',
    SHOW_ORDER_COMMENT = 'showOrderComment',
    DISABLE_ORGANIZATION_NR = 'disableOrganizationNr',
    DISABLE_COMPANY_TYPE = 'disableCompanyType',
    DISABLE_SOCIAL_SECURITY_NR = 'disableSocialSecurityNr',
    LOAD_FORM_WITH_ERRORS = 'loadFormWithErrors',
    B2B_MODE = 'b2bMode',
    B2B_MODE_FROM_CHECKOUT = 'b2bModeFromCheckout',
    B2B_BASE_STORE = 'b2bBaseStore',
    SHOW_POST_CODE = 'showPostCode',
    CHECKOUT_FORM_SUBMITTED = 'checkoutFormSubmitted',
    CROSS_SEGMENT_ACCESS_ERROR = 'crossSegmentAccessError',
    MASKED = 'masked',
    INVALID_CREDENTIALS = 'invalidCredentials',
    PAYPAL_PAYMENT_FAILED = 'paypalPaymentFailed',
    BANK_PAYMENT_FAILED = 'bankPaymentFailed',
    CREDIT_CARD_PAYMENT_FAILED = 'creditCardPaymentFailed',
    USER_LOGGED_IN = 'userLoggedIn',
    SHOW_ACCESS_CODE = 'showAccessCode',
    SEPARATE_INVOICE_EMAIL = 'separateInvoiceEmail',
    IDENTIFIED_CUSTOMER = 'identifiedCustomer',
    SSNAVAILABLE = 'ssnavailable',
    LINKS = 'links',
}
