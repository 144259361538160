import isSmartEditContainerPresent from '@spa-core-js/util/smartedit'
import { CollapsablePanel } from '@ui-elem/CollapsablePanel/CollapsablePanel'
import Modal from '@ui-elem-js/Modal'
import Radio from '@ui-elem/Radio/Radio'
import React, { useCallback, useEffect, useState } from 'react'
import browserSvc from '@spa-core-js/services/browserSvc'
import pkg from '@ic-site/../package.json'
import Button, { ButtonColors } from '@ui-elem/Button/Button'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import {
    setConsoleLogLevel as setConsoleLogLevelsInLogger,
    setHttpLogLevel as setHttpLogLevelInLogger,
    getLogLevels,
    LogLevel,
    Logger,
    LogLevelToMethodMap,
} from '@spa-core/logger'
import { createSelector } from 'reselect'
import { Store } from '@spa-core/store'
import { useDispatch, useSelector } from 'react-redux'
import { refreshSitemap } from '@spa-core/store/app/actions'

const getVersionFor = (versionName: string, sitePkg: any, packageName: 'spa-ec' | 'spa-core' | 'ui-elem') => {
    let res = 'unknown package'
    let packageFile: any
    switch (packageName) {
        case 'spa-ec':
            packageFile = require('@ic-site-js/../node_modules/@spa/spa-ec/package.json')
        case 'spa-core':
            packageFile = require('@ic-site-js/../node_modules/@spa/spa-core/package.json')
        case 'ui-elem':
            packageFile = require('@ic-site-js/../node_modules/@spa/ui-elem/package.json')
    }
    if (sitePkg.devDependencies[versionName]) {
        res = sitePkg.devDependencies[versionName]
        if (res !== 'latest') {
            res = packageFile.version
        }
    }
    return res
}

const getMetaByName = (metaName: string): string => {
    const metas: any = document.getElementsByTagName('meta')
    for (let i: number = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content')
        }
    }
    return ''
}

const WelcomMsg: any = {
    '✔︎': 'Doc is pre-rendered',
    '◉': 'Doc is not pre-rendered',
    '✘': 'Scod disabled',
}

const getAgeInMin = (modiedStr): number => {
    let age: number
    try {
        const d: Date = new Date(modiedStr)
        const now: Date = new Date()
        age = Math.round((now.getTime() - d.getTime()) / 60000)
    } catch {
        age = 999999
    }
    return age
}

export const CMS_DEBUG_MODE: string = 'cms-debug-mode'
export const STR_DEBUG_MODE: string = 'str-debug-mode'

const CACHE_CTRL: string = 'cache-ctrl'

type ComponentState = {
    refreshingSitemap: boolean
}

const DebugInfo: React.FC = () => {
    const [consoleLogLevel, setConsoleLogLevel] = useState<LogLevel>(getLogLevels()?.consoleLogLevel)
    const [httpLogLevel, setHttpLogLevel] = useState<LogLevel>(getLogLevels()?.httpLogLevel)
    const [cmsDebugMode, setCmsDebugMode] = useState<boolean>(browserSvc.sessionGet(CMS_DEBUG_MODE) === 'true')
    const [strDebugMode, setStrDebugMode] = useState<boolean>(browserSvc.sessionGet(STR_DEBUG_MODE) === 'true')
    const [cacheControll, setCacheControll] = useState<string>(browserSvc.cookieGet(CACHE_CTRL) || 'scod')
    const [visible, setVisible] = useState<boolean>(false)

    const refreshingSitemapStore = ({ reducers }: Store) => reducers[appReducerName].refreshingSitemap
    const selector = createSelector(
        [refreshingSitemapStore],
        (refreshingSitemap): ComponentState => ({
            refreshingSitemap,
        }),
    )
    const { refreshingSitemap }: ComponentState = useSelector(selector)

    const dispatch = useDispatch()

    const [latestLogMessage, setLatestLogMessage] = useState<string>()

    const sendHttpLog = (httpLogLevel: LogLevel) => {
        const message: string = `debug-test-log-${LogLevelToMethodMap[httpLogLevel]}-${Math.random() * 1000}`
        setLatestLogMessage(message)
        switch (httpLogLevel) {
            case LogLevel.ERROR:
                Logger.error(message)
            case LogLevel.WARN:
                Logger.warn(message)
            case LogLevel.INFO:
                Logger.info(message)
            case LogLevel.TRACE:
                Logger.trace(message)
            case LogLevel.LOG:
                Logger.log(message)
            case LogLevel.DEBUG:
                Logger.debug(message)
        }
    }

    const keyDown = useCallback((e: KeyboardEvent) => {
        if (e.code === 'KeyI' && e.ctrlKey && e.altKey) setVisible(true)
        if (e.code === 'KeyC' && e.ctrlKey && e.altKey) setVisible(false)
    }, [])

    const consoleLogLevelClick = (logLevel: LogLevel): void => {
        setConsoleLogLevel(logLevel)
        setConsoleLogLevelsInLogger(logLevel)
    }

    const httpLogLevelClick = (logLevel: LogLevel): void => {
        setHttpLogLevel(logLevel)
        setHttpLogLevelInLogger(logLevel)
    }

    if (getLogLevels()?.consoleLogLevel !== consoleLogLevel) {
        setConsoleLogLevel(getLogLevels()?.consoleLogLevel)
    }

    if (getLogLevels()?.httpLogLevel !== httpLogLevel) {
        setHttpLogLevel(getLogLevels()?.httpLogLevel)
    }

    useEffect(() => {
        document.addEventListener('keydown', keyDown)
        return () => {
            document.removeEventListener('keydown', keyDown)
        }
    }, [])

    const node: string = getMetaByName('SCOD-NODE')
    const modified: string = getMetaByName('SCOD-LAST-MOD')
    const hasessionid: string = getMetaByName('HASESSIONID')
    const preRenderType: string = getMetaByName('PRERENDER-TYPE')
    const hybrisNode: string = getMetaByName('HYBRIS-NODE')
    const age: number = getAgeInMin(modified)

    let indicator: string = preRenderType === 'prerender' ? '✔︎' : '◉'
    if (!node) {
        indicator = '✘'
    }

    return (
        <>
            <Modal mode={'dialog'} size={'small'} open={visible} onClose={() => setVisible(false)} scrollbar={true}>
                <Modal.Content>
                    <div>
                        <h2 className="p-2 text-center">
                            {WelcomMsg[indicator]} {age < 999999 ? 'and ' + age + ' min old' : ''}
                        </h2>
                        <div className={'panel_color'}>
                            <CollapsablePanel title={'Settings'} expanded={true}>
                                <div className="font-bold pl-4 text-center">
                                    Console LogLevel: {LogLevelToMethodMap[consoleLogLevel]}
                                </div>
                                <div className="px-4 pb-4 flex flex-col md:flex-row gap-1 justify-between mt-4 md:w-3/4 m-auto">
                                    <Button
                                        className="py-4"
                                        onClick={() => consoleLogLevelClick(LogLevel.NONE)}
                                        buttonText="none"
                                    />
                                    <Button
                                        className="py-4"
                                        onClick={() => consoleLogLevelClick(LogLevel.ERROR)}
                                        buttonText="error"
                                    />
                                    <Button
                                        className="py-4"
                                        onClick={() => consoleLogLevelClick(LogLevel.WARN)}
                                        buttonText="warn"
                                    />
                                    <Button
                                        className="py-4"
                                        onClick={() => consoleLogLevelClick(LogLevel.INFO)}
                                        buttonText="info"
                                    />
                                    <Button
                                        className="py-4"
                                        onClick={() => consoleLogLevelClick(LogLevel.TRACE)}
                                        buttonText="trace"
                                    />
                                    <Button
                                        className="py-4"
                                        onClick={() => consoleLogLevelClick(LogLevel.LOG)}
                                        buttonText="log"
                                    />
                                    <Button
                                        className="py-4"
                                        onClick={() => consoleLogLevelClick(LogLevel.DEBUG)}
                                        buttonText="debug"
                                    />
                                </div>
                                <div className="font-bold pl-4 text-center">
                                    Http LogLevel: {LogLevelToMethodMap[httpLogLevel]}
                                </div>
                                <div className="px-4 pb-4 flex justify-between mt-4 flex-col md:flex-row gap-1 md:w-3/4  m-auto">
                                    <Button className="py-4" onClick={() => httpLogLevelClick(LogLevel.NONE)} buttonText="none" />
                                    <Button
                                        className="py-4"
                                        onClick={() => httpLogLevelClick(LogLevel.ERROR)}
                                        buttonText="error"
                                    />
                                    <Button className="py-4" onClick={() => httpLogLevelClick(LogLevel.WARN)} buttonText="warn" />
                                    <Button className="py-4" onClick={() => httpLogLevelClick(LogLevel.INFO)} buttonText="info" />
                                    <Button
                                        className="py-4"
                                        onClick={() => httpLogLevelClick(LogLevel.TRACE)}
                                        buttonText="trace"
                                    />
                                    <Button className="py-4" onClick={() => httpLogLevelClick(LogLevel.LOG)} buttonText="log" />
                                    <Button
                                        className="py-4"
                                        onClick={() => httpLogLevelClick(LogLevel.DEBUG)}
                                        buttonText="debug"
                                    />
                                </div>
                                <div className="flex flex-col md:flex-row m-auto gap-1 justify-between">
                                    <div className="flex justify-center md:w-1/3">
                                        <Button
                                            onClick={() => {
                                                browserSvc.sessionSet(CMS_DEBUG_MODE, !cmsDebugMode)
                                                setCmsDebugMode(!cmsDebugMode)
                                            }}
                                            buttonText="Cms Info"
                                            buttonColor={cmsDebugMode ? ButtonColors.PRIMARY : ButtonColors.PALE}
                                        />
                                        <Button
                                            onClick={() => {
                                                browserSvc.sessionSet(CMS_DEBUG_MODE, !cmsDebugMode)
                                                setCmsDebugMode(!cmsDebugMode)
                                            }}
                                            buttonText="No Cms Info"
                                            buttonColor={!cmsDebugMode ? ButtonColors.PRIMARY : ButtonColors.PALE}
                                        />
                                    </div>
                                    <div className="flex justify-center md:w-1/3">
                                        <Button
                                            onClick={() => {
                                                browserSvc.sessionSet(STR_DEBUG_MODE, !strDebugMode)
                                                setStrDebugMode(!strDebugMode)
                                            }}
                                            buttonText="String keys"
                                            buttonColor={strDebugMode ? ButtonColors.PRIMARY : ButtonColors.PALE}
                                        />
                                        <Button
                                            onClick={() => {
                                                browserSvc.sessionSet(STR_DEBUG_MODE, !strDebugMode)
                                                setStrDebugMode(!strDebugMode)
                                            }}
                                            buttonText="No String Keys"
                                            buttonColor={!strDebugMode ? ButtonColors.PRIMARY : ButtonColors.PALE}
                                        />
                                    </div>
                                    <div className="flex justify-center md:w-1/3">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row">
                                                <Button
                                                    onClick={() => sendHttpLog(httpLogLevel)}
                                                    buttonText={`Send HTTP ${LogLevelToMethodMap[httpLogLevel]} log`}
                                                    buttonColor={ButtonColors.PRIMARY}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {latestLogMessage ? (
                                    <div className="font-bold text-center pl-4 pt-4">{`Log sent: ${latestLogMessage}`}</div>
                                ) : undefined}
                                <div className="font-bold text-center pl-4 pt-4">Scod setting for this client:</div>
                                <div className="flex flex-col md:flex-row m-auto w-1/3 pt-4">
                                    <Radio
                                        name={'scod'}
                                        label={'scod'}
                                        option={'scod'}
                                        value={cacheControll}
                                        onClick={() => {
                                            browserSvc.cookieSet(CACHE_CTRL, 'scod', { path: '/' })
                                            setCacheControll('scod')
                                        }}
                                    />
                                    <Radio
                                        name={'direct'}
                                        label={'direct'}
                                        option={'direct'}
                                        value={cacheControll}
                                        onClick={() => {
                                            browserSvc.cookieSet(CACHE_CTRL, 'direct', { path: '/' })
                                            setCacheControll('direct')
                                        }}
                                    />
                                    <Radio
                                        name={'evict'}
                                        label={'evict'}
                                        option={'evict'}
                                        value={cacheControll}
                                        onClick={() => {
                                            browserSvc.cookieSet(CACHE_CTRL, 'evict', { path: '/' })
                                            setCacheControll('evict')
                                        }}
                                    />
                                </div>
                            </CollapsablePanel>
                        </div>
                        <div className="panel_color mt-4">
                            <CollapsablePanel title={'Version ' + pkg.version}>
                                <div className="px-4 pb-4 panel_color">
                                    <div>
                                        spa-core version:{' '}
                                        <span className="font-bold">{getVersionFor('@spa/spa-core', pkg, 'spa-core')}</span>
                                    </div>
                                    <div>
                                        spa-ec version:{' '}
                                        <span className="font-bold">{getVersionFor('@spa/spa-ec', pkg, 'spa-ec')}</span>
                                    </div>
                                    <div>
                                        ui-elem version:{' '}
                                        <span className="font-bold">{getVersionFor('@spa/ui-elem', pkg, 'ui-elem')}</span>
                                    </div>
                                    <div>
                                        {}
                                        Build: <span className="font-bold">{BUILD_NUMBER}</span>
                                    </div>
                                </div>
                            </CollapsablePanel>
                        </div>
                        <div className="panel_color mt-4 flex justify-center">
                            {isSmartEditContainerPresent() ? (
                                <Button
                                    className="p-4"
                                    uppecase={true}
                                    disabled={refreshingSitemap}
                                    onClick={() => dispatch(refreshSitemap())}
                                    buttonText={refreshingSitemap ? 'Refreshing' : 'Refresh SiteMap For SmartEdit'}
                                />
                            ) : null}
                        </div>
                        <div className="panel_color mt-4">
                            <CollapsablePanel title={'Info'}>
                                {node ? (
                                    <div className={'origin pt-4 pl-2'}>
                                        Document comes from <strong>{node}</strong> and originates from{' '}
                                        <strong>{hybrisNode}</strong>
                                    </div>
                                ) : null}
                                {node ? (
                                    <div className="pt-4 pl-2">
                                        Document modifed <strong>{modified}</strong>
                                    </div>
                                ) : null}
                                <div className="pt-4 pl-2">
                                    JSESSIONID <strong>{browserSvc.cookieGet('JSESSIONID')}</strong>
                                </div>
                                <div className="pt-4 pl-2">
                                    ID2 <strong>{browserSvc.cookieGet('ID2')}</strong>
                                </div>
                                <div className="pt-4 pl-2">
                                    hasessionid <strong>{hasessionid}</strong>
                                </div>
                            </CollapsablePanel>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Action>
                    <Button className="py-4" uppecase={true} onClick={() => setVisible(false)} buttonText="Close" />
                </Modal.Action>
            </Modal>
        </>
    )
}

export default DebugInfo
