import axios, { AxiosError, AxiosResponse } from 'axios'
import { Logger } from '../../logger'

const getMany = async (paths: string[]): Promise<AxiosResponse[] | void> => {
    const results: any = await Promise.all(
        paths.map((path: string) =>
            axios.get(path).catch((e: AxiosError) => Logger.info(`Failed to load file: ${e?.config?.url}`)),
        ),
    )
    return results
}

export { getMany }
