import theme from '@ic-theme'
import { Logger } from '@spa-core/logger'
import net from '@spa-core-js/services/networkSvc'
import { getCampaignId, isCampaign } from '@spa-core-js/util/campaign'
import { isPuppeteer } from '@spa-ec/components/Loader/utils'
import { GoogleAnalyticsCategory, GoogleAnalyticsType, TrackingActionTypes } from '@spa-core/tracking/constants'
import { call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import { Store } from '../'
import { handleInitScripts } from '../../middleware/third-parties-middleware'
import { ActionTypes as AppActionTypes, NewsletterSubscriptionStatus, RequestPasswordStatus } from '../app/constants'
import { activateCampaign } from '../campaign/sagas'
import { fetchCart, setCartDataInStore } from '../cart/actions'
import { ActionTypes as CartActionTypes, NAME as cartReducerName } from '../cart/constants'
import { CartDataResponse, CartStore, SetCartDataPayload } from '../cart/interfaces'
import { addMessage, fetchMessageFromServer } from '../global-messages/actions'
import { ActionTypes as ProductActionTypes } from '../products/constants'
import { parseImageSubset } from '../products/utils'
import { selectSessionConfig } from '../utils'
import { ActionTypes, CustomerType } from './constants'
import {
    AccountProfile,
    AppStore,
    CheckCustomerResult,
    RequestPasswordResult,
    CheckCustomerToBeLoggedOffPayload,
    FetchPersonalizedDeliveryOptionsPayload,
    InitRequestResult,
    LogInPayload,
    PersonalizedDeliveryOffer,
    Promotions,
    RedirectResponse,
    RequestPasswordWithEmailPayload,
    RequestPasswordWithCustomerNumberPayload,
    SessionConfig,
    SetConfigPayload,
    SubscribeToNewsletterPayload,
    Order,
    OrderHistory,
    OrderEntry,
} from './interfaces'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { handleAbandonedCart } from '../cart/sagas'
import { navigateTo } from '../navigation/actions'
import { updateSessionConfig as updateSessionConfigAction } from './actions'
import { MessageLevels } from '../global-messages/constants'
import { Message } from '../global-messages/interfaces'
import browserSvc from '@spa-core-js/services/browserSvc'
import { t } from '@spa-core/locale'
import { initLocalesFromFile } from '../../locale'
import { configLogger } from '@spa-core/logger'
import { getCartEntryStockStatus, parseCartEntries } from '../cart/utils'
import { preloadExternalJs } from './utils'

const WARNING_CART_ONEBUYONLY_HAS_REMOVED: string = 'warning.popup.cart.onebuyonly.has.removed'
const WARNING_CART_ONEBUYONLY_HAS_CONSUMED: string = 'warning.popup.cart.onebuyonly.has.consumed'

const getSessionStore = ({ reducers }: Store) => reducers[appReducerName]
const getIdentifiedLabel = (userLoggedIn: boolean, identifiedMode: boolean): number => {
    let identifiedLabel: number = 0
    if (userLoggedIn) {
        identifiedLabel = 2
    } else if (identifiedMode) {
        identifiedLabel = 1
    }
    return identifiedLabel
}

export function* fetchPromotions() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/promotionData`
    try {
        const promotions: Promotions = yield call(() => net.get(url))
        if (promotions) {
            yield put({
                type: ActionTypes.FETCHED_PROMOTIONS,
                payload: {
                    promotions,
                },
            })
            yield put({
                type: ProductActionTypes.FETCHED_PROMOTIONS,
                payload: {
                    promotions,
                },
            })
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* initApplication() {
    yield call(fetchInitConfig)
    if (isCampaign()) {
        const campaignId: string = `${getCampaignId()}${location.search}`
        yield activateCampaign(campaignId)
    }
    const { abandonedCartData }: CartStore = yield select((state) => state?.reducers?.[cartReducerName])
    yield call(handleAbandonedCart, { type: '', payload: { abandonedCartData } })
}

export function* fetchInitConfig() {
    const documentUrlPrefix: string = `${window['sessionConf'].urlPrefix}`
    const sessionConfig = yield select(selectSessionConfig)
    const urlPrefix: string = sessionConfig?.urlPrefix || documentUrlPrefix
    let fromScodQs: string = ''
    if (isPuppeteer) {
        fromScodQs += '&from_scod=true'
    }
    const url: string = `${urlPrefix}/rest/v1/page/init?${fromScodQs}`
    try {
        yield put({
            type: ActionTypes.SET_UPDATING_SESSION_CONFIG,
            payload: {
                value: true,
            },
        })

        const data: InitRequestResult = yield call(() => net.get(url))
        const prevBaseStore: string = browserSvc.sessionGet('prev-basestore')
        const prevTotalItemsInCart: string = browserSvc.sessionGet('prev-cart-items-count')
        if (
            prevBaseStore &&
            prevTotalItemsInCart &&
            prevBaseStore !== sessionConfig.currentBaseStoreId &&
            data.cartData.totalItems.toString() !== prevTotalItemsInCart
        ) {
            const payload: Message = {
                title: t('cart.updated.store.changed.title'),
                message: '',
                level: MessageLevels.INFO,
                id: 'cart-updated',
                displaySeconds: 10,
            }
            yield put(addMessage(payload))
        }
        if (prevBaseStore) {
            browserSvc.sessionRemove('prev-basestore')
        }
        if (prevTotalItemsInCart) {
            browserSvc.sessionRemove('prev-cart-items-count')
        }

        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.config.placeholderImageSrc}`
        const cartData: CartDataResponse = {
            ...data.cartData,
            entries: parseCartEntries(data.cartData?.entries, fallbackImage),
        }
        const cartDataPayload: SetCartDataPayload = {
            cartData,
            abandonedCartData: data.abandonedCartData,
            initCart: true,
        }
        yield put(setCartDataInStore(cartDataPayload))
        yield put({
            type: CartActionTypes.FETCH_CART,
        })

        if (data.crossSellProductCodes) {
            yield put({
                type: ProductActionTypes.FETCHED_CROSS_SELL_PRODUCT_CODES,
                payload: {
                    productCodes: data.crossSellProductCodes,
                },
            })
        }

        const promotions: Promotions = data.promotionData
        yield put({
            type: AppActionTypes.FETCHED_PROMOTIONS,
            payload: {
                promotions,
            },
        })

        if (data.scripts) {
            handleInitScripts(data.scripts, { ...sessionConfig, ...data.sessionConf })
        }

        /**
         * Load js files injected by third party scripts
         */
        yield preloadExternalJs(data?.sessionConf?.siteName)

        yield initLocalesFromFile(
            data?.sessionConf?.contextPath,
            data?.sessionConf?.siteName,
            data?.sessionConf?.currentLanguageIsocode,
            data?.sessionConf?.countryIso,
            data?.sessionConf?.isDecimalPricingEnabled,
        )
        configLogger({
            urlPrefix: data?.sessionConf?.urlPrefix,
            contextPath: data?.sessionConf?.contextPath,
            siteName: data?.sessionConf?.siteName,
            csrfToken: data?.sessionConf?.CSRFToken,
        })

        const identifiedLabel: number = getIdentifiedLabel(data.sessionConf?.userLoggedIn, data.sessionConf?.identifiedMode)
        const appStore: AppStore = yield select(getSessionStore)
        const includeInAnalytics: boolean = appStore.previousIdentifiedGoogleAnalyticsLabel !== identifiedLabel
        if (includeInAnalytics) {
            yield put({
                type: TrackingActionTypes.SESSION_CONFIG_UPDATED,
                payload: {
                    gaCat: GoogleAnalyticsCategory.SESSION,
                    gaType: GoogleAnalyticsType.IDENTIFIED,
                    gaLabel: identifiedLabel,
                },
            })
        }

        const setConfigPayload: SetConfigPayload = {
            sessionConfig: data.sessionConf,
            googleAnalyticsLabel: identifiedLabel,
        }

        yield put({
            type: ActionTypes.SET_CONFIG,
            payload: setConfigPayload,
        })

        yield put({
            type: ActionTypes.SET_UPDATING_SESSION_CONFIG,
            payload: {
                value: false,
            },
        })
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* updateSessionConfig() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/session/config?sessionSvc`
    try {
        yield put({
            type: ActionTypes.SET_UPDATING_SESSION_CONFIG,
            payload: {
                value: true,
            },
        })
        const data = yield call(() => net.get(url))
        const updatedSessionConfig: SessionConfig = {
            ...data.value,
        }
        if (data.value['csrfToken'] || data.value['CSRFToken']) {
            updatedSessionConfig.CSRFToken = data.value['csrfToken'] || data.value['CSRFToken']
        }
        const identifiedLabel: number = getIdentifiedLabel(updatedSessionConfig.userLoggedIn, updatedSessionConfig.identifiedMode)
        const appStore: AppStore = yield select(getSessionStore)
        const includeInAnalytics: boolean = appStore.previousIdentifiedGoogleAnalyticsLabel !== identifiedLabel

        if (includeInAnalytics) {
            yield put({
                type: TrackingActionTypes.SESSION_CONFIG_UPDATED,
                payload: {
                    gaCat: GoogleAnalyticsCategory.SESSION,
                    gaType: GoogleAnalyticsType.IDENTIFIED,
                    gaLabel: identifiedLabel,
                },
            })
        }

        const setConfigPayload: SetConfigPayload = {
            sessionConfig: updatedSessionConfig,
            googleAnalyticsLabel: identifiedLabel,
        }
        yield put({
            type: ActionTypes.SET_CONFIG,
            payload: setConfigPayload,
        })

        yield put({
            type: ActionTypes.SET_UPDATING_SESSION_CONFIG,
            payload: {
                value: false,
            },
        })

        if (updatedSessionConfig.oneBuyOnlyOptionRemoved) {
            yield put(fetchMessageFromServer(WARNING_CART_ONEBUYONLY_HAS_REMOVED))
        }
        if (updatedSessionConfig.oneBuyOnlyOfferConsumed) {
            yield put(fetchMessageFromServer(WARNING_CART_ONEBUYONLY_HAS_CONSUMED))
        }
        if (data.storedTokenModificationMessageKey) {
            yield put(fetchMessageFromServer(data.storedTokenModificationMessageKey))
        }
        if (data.subscriptionProductThresholdReachedMessageKey) {
            yield put(fetchMessageFromServer(data.subscriptionProductThresholdReachedMessageKey))
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* checkCustomerToBeLoggedOff({ payload }: any) {
    const { targetCountryUrl }: CheckCustomerToBeLoggedOffPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)

    const customerType: CustomerType = sessionConfig.b2bMode ? CustomerType.PRIVATE : CustomerType.COMPANY
    let postdata: string = `customerType=${customerType}`
    if (sessionConfig.currentBaseStoreId) {
        postdata += `&baseStoreId=${sessionConfig.currentBaseStoreId}`
    }
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/_s/checkCustomer`
    try {
        const result: CheckCustomerResult = yield call(() => net.post(url, postdata))
        if (result?.logOffCustomer) {
            yield put({
                type: ActionTypes.SET_CUSTOMER_TYPE_CHANGE_WARNING,
                payload: {
                    showDialog: true,
                    messageText: result.messageText,
                    messageTitle: result.messageTitle,
                    popupTitle: result.popupTitle,
                    cancelText: result.cancelText,
                    logoutText: result.logoutText,
                    targetCountryUrl,
                },
            })
        } else if (!result?.logOffCustomer) {
            yield switchCustomerType()
        } else {
            const currentUrlResult: any = yield call(() => net.get(`${url}?currentURL=${window.location.pathname}`))
            const url: string = currentUrlResult.redirectURL
            // yield put(navigateTo({ url }))
            /**
             * DO NOT REMOVE window.location.href
             * WILL BREAK CHECKOUT IF REDIRECTED BY OTHER MEANS
             */
            window.location.href = url
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url, postdata)
    }
}

export function* switchCustomerType() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = sessionConfig.b2bMode ? '/rest/v1/_s/private' : '/rest/v1/_s/company'
    try {
        const redirect: RedirectResponse = yield call(() => net.get(`${sessionConfig.urlPrefix}${url}`))
        // yield put(navigateTo({ url: redirect.redirectFullURL }))
        /**
         * DO NOT REMOVE window.location.href
         * WILL BREAK CHECKOUT IF REDIRECTED BY OTHER MEANS
         */
        window.location.href = redirect.redirectFullURL
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, `${sessionConfig.urlPrefix}${url}`)
    }
}

export function* logoutSwitchCustomerTypeOrChangeLanguage({ payload }: any) {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const customerType: CustomerType = sessionConfig.b2bMode ? CustomerType.PRIVATE : CustomerType.COMPANY
    const logoutURL: string = sessionConfig.currentBaseStoreId
        ? `${sessionConfig.urlPrefix}/logout`
        : `${sessionConfig.urlPrefix}/logout?customerType=${customerType}`
    try {
        /**
         * Log out customer
         */
        yield call(() => net.get(logoutURL))

        /**
         * If targetCountryUrl exists, change language else switch customer type
         */
        const { targetCountryUrl } = payload
        if (targetCountryUrl) {
            yield put(navigateTo({ url: targetCountryUrl }))
        } else {
            yield switchCustomerType()
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, logoutURL)
    }
}

export function* fetchPersonalizedDeliveryOptions({ payload }: any) {
    const { postcode }: FetchPersonalizedDeliveryOptionsPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/transporters/${postcode || ''}`
    try {
        const personalizedDeliveryOptions: PersonalizedDeliveryOffer = yield call(() => net.get(url))
        yield put({
            type: ActionTypes.FETCHED_PERSONALIZED_DELIVERY_OPTIONS,
            payload: personalizedDeliveryOptions,
        })
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchAccountProfile() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/users/current/account/profile`
    try {
        const accountProfile: AccountProfile = yield call(() => net.get(url))
        if (accountProfile) {
            yield put({
                type: ActionTypes.FETCHED_ACCOUNT_PROFILE,
                payload: accountProfile,
            })
        }
    } catch (e: any) {
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* subscribeToNewsletter({ payload }: any) {
    const { email }: SubscribeToNewsletterPayload = payload
    try {
        const sessionConfig: SessionConfig = yield select(selectSessionConfig)
        const result = yield call(() =>
            net.postJSONP(sessionConfig.newsLetterSubscriptionURL, {
                email,
                fName: '',
                lName: '',
                name: '',
                ctry: sessionConfig.countryIso.toLocaleLowerCase(),
                lang: sessionConfig.currentLanguageIsocode.toLocaleLowerCase(),
                pc: sessionConfig.portalId,
                sid: sessionConfig.newsLetterServiceId,
                cid: sessionConfig.newsLetterCampaignNo,
                en: sessionConfig.neolaneAuthToken,
                ts: sessionConfig.neolaneAuthTimeStamp,
            }),
        )
        const subscriptionStatus: NewsletterSubscriptionStatus =
            result?.status === 'ok'
                ? NewsletterSubscriptionStatus.SUBSCRIPTION_SUCCESS
                : NewsletterSubscriptionStatus.SUBSCRIPTION_FAILED
        yield put({
            type: ActionTypes.SUBSCRIBE_TO_NEWSLETTER_RESULT,
            payload: {
                subscriptionStatus,
            },
        })
    } catch {
        yield put({
            type: ActionTypes.SUBSCRIBE_TO_NEWSLETTER_RESULT,
            payload: {
                subscriptionStatus: NewsletterSubscriptionStatus.SUBSCRIPTION_FAILED,
            },
        })
    }
}

export function* requestPasswordWithEmail({ payload }: any) {
    const { email }: RequestPasswordWithEmailPayload = payload

    try {
        const sessionConfig: SessionConfig = yield select(selectSessionConfig)
        const result: RequestPasswordResult = yield call(() =>
            net.postJSON(sessionConfig.urlPrefix + '/rest/v1/pw/request', { email }),
        )

        const requestPasswordEmailStatus: RequestPasswordStatus = result?.errors
            ? RequestPasswordStatus.REQUEST_PASSWORD_EMAIL_FAILED
            : RequestPasswordStatus.REQUEST_PASSWORD_EMAIL_SUCCESS

        yield put({
            type: ActionTypes.REQUEST_PASSWORD_RESULT,
            payload: {
                errors: result.errors,
                success: result.success,
                requestPasswordStatus: requestPasswordEmailStatus,
            },
        })
    } catch {
        yield put({
            type: ActionTypes.REQUEST_PASSWORD_RESULT,
            payload: {
                requestPasswordEmailStatus: RequestPasswordStatus.REQUEST_PASSWORD_EMAIL_FAILED,
            },
        })
    }
}

export function* requestPasswordWithCustomerNumber({ payload }: any) {
    const { customerNumber }: RequestPasswordWithCustomerNumberPayload = payload
    try {
        const sessionConfig: SessionConfig = yield select(selectSessionConfig)
        const result: RequestPasswordResult = yield call(() =>
            net.postJSON(sessionConfig.urlPrefix + '/rest/v1/pw/requestByCustNo', { customerNumber }),
        )

        const requestPasswordCustomerNumberStatus: RequestPasswordStatus = result?.errors
            ? RequestPasswordStatus.REQUEST_PASSWORD_CUSTOMER_NUMBER_FAILED
            : RequestPasswordStatus.REQUEST_PASSWORD_CUSTOMER_NUMBER_SUCCESS

        yield put({
            type: ActionTypes.REQUEST_PASSWORD_RESULT,
            payload: {
                errors: result.errors,
                success: result.success,
                requestPasswordStatus: requestPasswordCustomerNumberStatus,
            },
        })
    } catch {
        yield put({
            type: ActionTypes.REQUEST_PASSWORD_RESULT,
            payload: {
                requestPasswordCustomerNumberStatus: RequestPasswordStatus.REQUEST_PASSWORD_CUSTOMER_NUMBER_FAILED,
            },
        })
    }
}

const getLoginErrorMessage = (status: number): string => {
    let loginErrorMessage: string
    switch (status) {
        case 251:
            loginErrorMessage = 'login.error.b2c.segment.access.attempt'
        case 252:
            loginErrorMessage = 'login.error.b2b.segment.access.attempt'
        default:
            loginErrorMessage = 'login.error.account.not.found.title'
    }
    return loginErrorMessage
}
export function* logIn({ payload }: any) {
    try {
        yield put({
            type: ActionTypes.SET_LOGGING_IN,
            payload: {
                loggingIn: true,
            },
        })
        const { userName, password: passwordPayload, rememberMe, redirectToAfterLogin }: LogInPayload = payload
        const password: string = encodeURIComponent(passwordPayload)
        const sessionConfig: SessionConfig = yield select(selectSessionConfig)
        const url: string = `${sessionConfig.urlPrefix}/login`
        const { xhr } = yield call(() =>
            net.post(url, `username=${userName}&password=${password}&_spring_security_remember_me=${rememberMe}`),
        )
        yield put({
            type: ActionTypes.SET_LOG_IN_ERROR_MESSAGE,
            payload: {
                loginErrorMessage: undefined,
            },
        })
        yield put(fetchCart())
        yield put(updateSessionConfigAction())
        yield put({
            type: ActionTypes.SET_LOGGING_IN,
            payload: {
                loggingIn: false,
            },
        })

        if (xhr?.status) {
            if (xhr.status === 250) {
                /**
                 * DO NOT REMOVE window.location.href
                 * WILL BREAK CHECKOUT IF REDIRECTED BY OTHER MEANS
                 */
                window.location.href = `${sessionConfig.urlPrefix}/`
            } else {
                const loginErrorMessage: string = getLoginErrorMessage(xhr.status)
                yield put({
                    type: ActionTypes.SET_LOG_IN_ERROR_MESSAGE,
                    payload: {
                        loginErrorMessage,
                    },
                })
            }
        } else {
            /**
             * DO NOT REMOVE window.location.href
             * WILL BREAK CHECKOUT IF REDIRECTED BY OTHER MEANS
             */
            window.location.href = redirectToAfterLogin
        }
    } catch (error: any) {
        if (error?.status) {
            const loginErrorMessage: string = getLoginErrorMessage(error.status)
            yield put({
                type: ActionTypes.SET_LOG_IN_ERROR_MESSAGE,
                payload: {
                    loginErrorMessage,
                },
            })
        }
        yield put({
            type: ActionTypes.SET_LOGGING_IN,
            payload: {
                loggingIn: false,
            },
        })
    }
}

export function* refreshSitemap() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v2/sitemap?refreshSitemapForSE=true`
    try {
        yield put({
            type: ActionTypes.REFRESHING_SITEMAP,
            payload: {
                refreshing: true,
            },
        })
        yield call(() => net.get(url))

        yield put({
            type: ActionTypes.REFRESHING_SITEMAP,
            payload: {
                refreshing: false,
            },
        })

        location.reload()
    } catch (e: any) {
        yield put({
            type: ActionTypes.REFRESHING_SITEMAP,
            payload: {
                refreshing: false,
            },
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchOrder({ payload }: any) {
    const { orderCode } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/users/current/account/order/${orderCode}`
    try {
        yield put({
            type: ActionTypes.SET_FETCHING_ORDER,
            payload: {
                orderCode,
                fetching: true,
            },
        })
        const order: Order = yield call(() => net.get(url))
        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.config.placeholderImageSrc}`
        const entries: OrderEntry[] = order.entries.map((orderEntry: OrderEntry) => ({
            ...orderEntry,
            entryStockStatus: getCartEntryStockStatus(orderEntry.entryStockStatus),
            product: {
                ...orderEntry.product,
                imagesSubset: parseImageSubset(orderEntry.product.imagesSubset, fallbackImage),
                stockStatus: getCartEntryStockStatus(orderEntry.product.stockStatus),
            },
        }))
        if (order) {
            yield put({
                type: ActionTypes.FETCHED_ORDER,
                payload: {
                    order: {
                        ...order,
                        entries,
                    },
                },
            })
        }
        yield put({
            type: ActionTypes.SET_FETCHING_ORDER,
            payload: {
                orderCode,
                fetching: false,
            },
        })
    } catch (e: any) {
        yield put({
            type: ActionTypes.SET_FETCHING_ORDER,
            payload: {
                orderCode,
                fetching: false,
            },
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export function* fetchOrderHistory({ payload }: any) {
    const { page } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/users/current/account/orders?page=${page}`
    try {
        yield put({
            type: ActionTypes.SET_FETCHING_ORDER_HISTORY,
            payload: {
                fetching: true,
            },
        })
        const orderHistory: OrderHistory = yield call(() => net.get(url))

        yield put({
            type: ActionTypes.FETCHED_ORDER_HISTORY,
            payload: {
                orderHistory,
            },
        })
        yield put({
            type: ActionTypes.SET_FETCHING_ORDER_HISTORY,
            payload: {
                fetching: false,
            },
        })
    } catch (e: any) {
        yield put({
            type: ActionTypes.SET_FETCHING_ORDER_HISTORY,
            payload: {
                fetching: false,
            },
        })
        Logger.error({ message: e.message }, e.code, e.status, url)
    }
}

export const watchers = [
    takeLatest(ActionTypes.LOG_IN, logIn),
    takeLatest(ActionTypes.FETCH_PROMOTIONS, fetchPromotions),
    takeLeading(ActionTypes.INIT_APPLICATION, initApplication),
    takeEvery(ActionTypes.UPDATE_SESSION_CONFIG, updateSessionConfig),
    takeLeading(ActionTypes.FETCH_INIT_CONFIG, fetchInitConfig),
    takeLeading(ActionTypes.CHECK_CUSTOMER_TO_BE_LOGGED_OFF, checkCustomerToBeLoggedOff),
    takeLeading(ActionTypes.LOG_OUT_SWITCH_CUSTOMER_TYPE_OR_CHANGE_LANGUAGE, logoutSwitchCustomerTypeOrChangeLanguage),
    takeLatest(ActionTypes.FETCH_PERSONALIZED_DELIVERY_OPTIONS, fetchPersonalizedDeliveryOptions),
    takeLatest(ActionTypes.FETCH_ACCOUNT_PROFILE, fetchAccountProfile),
    takeLatest(ActionTypes.SUBSCRIBE_TO_NEWSLETTER, subscribeToNewsletter),
    takeLatest(ActionTypes.REQUEST_PASSWORD_EMAIL, requestPasswordWithEmail),
    takeLatest(ActionTypes.REQUEST_PASSWORD_CUSTOMERNUMBER, requestPasswordWithCustomerNumber),
    takeLatest(ActionTypes.REFRESH_SITEMAP, refreshSitemap),
    takeLatest(ActionTypes.FETCH_ORDER_HISTORY, fetchOrderHistory),
    takeLatest(ActionTypes.FETCH_ORDER, fetchOrder),
]
