import React from 'react'
import Icon, { Icons } from '../Icon'

interface Props {
    className?: string
    size?: number
}

const Spinner = ({ className = '', size = 32 }: Props): React.ReactNode => (
    <div className={`flex w-full justify-center m-auto ${className}`}>
        <Icon icon={Icons.Spinner} size={size} />
    </div>
)

export default Spinner
