import React from 'react'
import cleanKey from '@spa-core-js/util/cleanKey'
import { ContentSlotComponentProps } from '../../interfaces'
import { NAME, USPWrapperTheme } from './USPWrapper.theme'
import { getComponentTheme } from '@spa-core/theme/theme'

const theme: USPWrapperTheme = getComponentTheme<USPWrapperTheme>(NAME, {
    maximumItemsMobile: 1,
    maximumItemsIpad: 3,
})

export interface Props extends ContentSlotComponentProps {
    children: React.ReactNode[]
}

const USPWrapper = ({ ssrKey, elementUid, contentSlotCatalogVersion, componentParentType, children }: Props): React.ReactNode => (
    <div
        className="ic-grid-center print:hidden e2e-ver-usp-wrapper smartEditComponent"
        data-smartedit-component-id={cleanKey(ssrKey)}
        data-smartedit-component-uuid={cleanKey(elementUid)}
        data-smartedit-catalog-version-uuid={cleanKey(contentSlotCatalogVersion)}
        data-smartedit-component-type={cleanKey(componentParentType)}
    >
        <div className="flex flex-row w-full justify-around">
            {children.map((child: React.ReactElement, index: number) => {
                return (
                    <div key={`usp-item-${child.key}`} className={index < theme.maximumItemsMobile ? '' : 'hidden md:block'}>
                        {child}
                    </div>
                )
            })}
        </div>
    </div>
)

export default USPWrapper
